import { action, observable, computed, toJS } from 'mobx';
import * as merge from 'deepmerge';
import Fuse from 'fuse.js';

import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import { reportError } from 'config/sentry';

import { Modality } from 'types';
import {
  DASHBOARD_FILTER_STATUSES,
  DASHBOARD_SORT_FILEDS,
  PREMIUM_VT_SESSION_STATES,
} from 'config/constants';
import { getLabUsedHoursGroupByCourse } from 'services/LabHoursService';

class OfferingsListStore {
  constructor(
    rootStore,
    userStore,
    catalogStore,
    progressStore,
    examsStore,
    classesStore,
    learningPathStore,
    premiumSessionsStore,
  ) {
    this.rootStore = rootStore;
    this.userStore = userStore;
    this.catalogStore = catalogStore;
    this.progressStore = progressStore;
    this.examsStore = examsStore;
    this.classesStore = classesStore;
    this.learningPathStore = learningPathStore;
    this.premiumSessionsStore = premiumSessionsStore;
  }

  @observable _currentPage = 1;

  @observable entriesPerPage = 6;

  @observable _filteredEntries = [];

  @observable _alaCarteCourses = [];

  @observable searchResults = null;

  @observable isSortAscending = false;

  @observable sortByField = DASHBOARD_SORT_FILEDS.lastAccessedDate;

  @observable filters = {
    modality: [],
    modalityText: '',
    statuses: {},
    search: '',
  };

  @observable filterMatchFunc = null;

  @observable _filteredOfferings = [];

  @observable loaded = false;

  @observable hasCatalogData = false;

  @observable hasRelatedData = false;

  @observable labs = [];

  @computed get filteredOfferings() {
    const checkedFiltersLength = Object.keys(this.filters).filter((key) => {
      return this.filters[key]?.length
        ? this.filters[key].length > 0
        : Object.keys(this.filters[key]).filter(
            (k) => this.filters[key][k].length > 0,
          ).length > 0;
    });

    const showFilteredOptions =
      checkedFiltersLength.length > 0 ||
      this.sortByField ||
      this.isSortAscending;

    return showFilteredOptions ? this._filteredOfferings : this.offerings;
  }

  filterStatus = (entry) => {
    const _statusesToFilterBy = this.filters.statuses[entry.modality];
    switch (entry.modality) {
      case Modality.Course:
      case Modality.TechOverview:
        if (_statusesToFilterBy && _statusesToFilterBy.length > 0) {
          const progress = Math.max(
            ...entry.offering.map((o) => o.total_progress),
          );

          const progressInPercentage = Math.round(progress * 100);

          return (
            (_statusesToFilterBy.includes('not_started') &&
              progressInPercentage <= 0) ||
            (_statusesToFilterBy.includes('in_progress') &&
              progressInPercentage > 0 &&
              progressInPercentage < 100) ||
            (_statusesToFilterBy.includes('completed') &&
              progressInPercentage >= 75)
          );
        }
        return true;

      case Modality.Exam:
      case Modality.PremVTClass:
      case Modality.PremVTEnrollment:
        if (_statusesToFilterBy && _statusesToFilterBy.length > 0) {
          return _statusesToFilterBy.includes(entry.status);
        }
        return true;

      default:
        return true;
    }
  };

  @action searchEntries = (string) => {
    const options = {
      shouldSort: true,
      threshold: 0,
      tokenize: true,
      matchAllTokens: true,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['code', 'title', 'modality'],
    };

    if (!string.length) {
      return this.offerings;
    }

    const fuse = new Fuse(this.offerings, options);
    const _searchResults = fuse.search(string);
    return _searchResults;
  };

  /**
   * Filter entries by search text, modality filter, status filter and then sort
   */
  @action filterEntries = () => {
    // filter by search string:
    let _filteredOfferings = this.searchEntries(this.filters.search);

    // filter by modality:
    if (this.filters.modality.length > 0 || this.filterMatchFunc) {
      _filteredOfferings = _filteredOfferings.filter((entry) => {
        const offering = first(entry?.offering);

        if (this.filterMatchFunc && offering) {
          return this.filterMatchFunc(offering);
        }
        return this.filters.modality.includes(entry.modality);
      });
    }

    // filter by status:
    _filteredOfferings = _filteredOfferings.filter((entry) =>
      this.filterStatus(entry),
    );

    // sort:
    this._filteredOfferings = this.sortOfferings(_filteredOfferings);
  };

  @action onSearchOfferings = (query) => {
    this.filters.search = query;

    this.filterEntries();
  };

  @computed get filterKeys() {
    return Object.keys(this.filters);
  }

  clearFilters = () => {
    this.filterKeys.forEach((key) => {
      this.filters[key] = this.filters[key].length ? [] : {};
    });
    this.filterEntries();
  };

  @action onSelectOfferings = (selectedOfferings, selectedStatuses) => {
    const filterBy = selectedOfferings?.value; // selectedOfferings.map(o => o.value);
    this.filterMatchFunc = selectedOfferings?.match;
    this.filters.modality = filterBy || [];
    this.filters.modalityText = selectedOfferings?.label || '';
    this.onSelectStatuses(selectedStatuses);
    // remove status filter for modalities that doesnt exists
    // this.filters.statuses = Object.fromEntries(
    //   Object.entries(this.filters.statuses).filter(
    //     filter => filterBy === filter[0], // filterBy.includes(filter[0]),
    //   ),
    // );
  };

  @action onSelectStatuses = (selectedStatuses) => {
    const filterBy = flatten(selectedStatuses.map((o) => o.value));

    const _statusesToFilter = {};
    // construct selected statuses by modality
    // eg.: statuses = {exam: ['scheduled'], course: ['inprogress']}
    Object.keys(DASHBOARD_FILTER_STATUSES).forEach((modality) => {
      _statusesToFilter[modality] = filterBy.filter((s) =>
        DASHBOARD_FILTER_STATUSES[modality].includes(s),
      );
    });
    this.filters.statuses = _statusesToFilter;
    this.filterEntries();
  };

  @action sortOfferings = (offerings) => {
    if (!this.isSortAscending) {
      return offerings
        .slice()
        .sort((a, b) =>
          this.sortByField === DASHBOARD_SORT_FILEDS.lastAccessedDate
            ? new Date(b[this.sortByField]) - new Date(a[this.sortByField])
            : b[this.sortByField].localeCompare(a[this.sortByField]),
        );
    }

    return offerings
      .slice()
      .sort((a, b) =>
        this.sortByField === DASHBOARD_SORT_FILEDS.lastAccessedDate
          ? new Date(a[this.sortByField]) - new Date(b[this.sortByField])
          : a[this.sortByField] &&
            a[this.sortByField].localeCompare(b[this.sortByField]),
      );
  };

  @action onSortBy = (sortBy) => {
    this.sortByField = sortBy;
    this.isSortAscending = !this.isSortAscending;
    this.filterEntries();
  };

  extendProgressRecord = (progressRecord, offering) => {
    progressRecord = {
      ...progressRecord,
      title:
        offering[0]?.title ||
        offering.title ||
        offering[0]?.videoClassroom?.title ||
        'N/A',
      modality:
        offering[0]?.modality ||
        offering.modality ||
        offering[0]?.videoClassroom?.modality ||
        'N/A',
      code:
        offering[0]?.code ||
        offering.code ||
        offering[0]?.videoClassroom?.code ||
        'N/A',
      // eslint-disable-next-line camelcase
      slug: progressRecord?.course_slug || progressRecord?.exam_slug || null,
      offering,
    };

    progressRecord.modality =
      progressRecord.modality === Modality.VideoClassroom
        ? Modality.Course
        : progressRecord.modality;

    return progressRecord;
  };

  // eslint-disable-next-line class-methods-use-this
  extendOfferings(mergedProgressRecords) {
    const extendedOfferings = mergedProgressRecords
      .map((progressRecord) => {
        const offering = this.getOffering(progressRecord);
        if (offering) {
          return this.extendProgressRecord(progressRecord, offering);
        }

        return null;
      })
      .filter((extendOffering) => {
        return extendOffering != null;
      });
    return extendedOfferings;
  }

  extendOfferingsForPremiumSessions(mergePremiumOfferings) {
    const extendedPremiumOfferings = mergePremiumOfferings?.map(
      (premiumOffering) => {
        // eslint-disable-next-line camelcase
        const courseCode = premiumOffering?.offering_slug.split('-')[0];
        const catalogOffering =
          this.catalogStore.groupedCatalogEntries[courseCode];

        const enrollments =
          this.classesStore.groupedPremiumSessionEnrollmentsByOffering[
            premiumOffering.offering_slug
          ];

        // last access date for each offering, descending
        const sortedEnrollmentsbyLastAccessDate = flatMap(
          enrollments,
          'state_transition_log',
        )
          ?.map((log) => log?.timestamp)
          ?.filter((log) => log !== undefined)
          ?.slice()
          ?.sort((a, b) => {
            return new Date(b) - new Date(a);
          });

        const completedEnrollments = enrollments?.filter(
          (e) => e.state === PREMIUM_VT_SESSION_STATES.completed,
        );

        const enrolledEnrollments = enrollments?.filter(
          (e) => e.state === PREMIUM_VT_SESSION_STATES.enrolled,
        );
        // set status for each offering : completed, in_progress, null
        const premiumOfferingStatuses =
          enrollments?.length > 0
            ? premiumOffering?.scheduledSessions?.map((ss) => {
                let premOfferingStatus = null;
                const hasAtleastOneEnrollmentCompleted =
                  completedEnrollments?.find(
                    (e) => e.premvt_session_uuid === ss.doc_id,
                  );

                premOfferingStatus =
                  hasAtleastOneEnrollmentCompleted && 'completed';

                const hasAtleastOneEnrollmentScheduled =
                  enrolledEnrollments?.find(
                    (e) => e.premvt_session_uuid === ss.doc_id,
                  );

                premOfferingStatus =
                  premOfferingStatus !== 'completed'
                    ? (hasAtleastOneEnrollmentScheduled && 'in_progress') ||
                      premOfferingStatus
                    : premOfferingStatus;

                return premOfferingStatus;
              })
            : [];

        const completedOfferings = premiumOfferingStatuses?.filter(
          (s) => s === 'completed',
        )?.length;

        const inProgressOfferings = premiumOfferingStatuses?.filter(
          (s) => s === 'in_progress',
        )?.length;

        const premiumOfferingStatus =
          completedOfferings > 0 || inProgressOfferings > 0
            ? (completedOfferings ===
                premiumOffering?.scheduledSessions?.length &&
                'completed') ||
              'in_progress'
            : null;

        if (premiumOfferingStatus) {
          const lastAccessedDate =
            sortedEnrollmentsbyLastAccessDate?.length > 0
              ? sortedEnrollmentsbyLastAccessDate[0]
              : premiumOffering['@timestamp'];

          return {
            '@timestamp': lastAccessedDate,
            course_code: courseCode,
            course_slug: premiumOffering.offering_slug,
            title: catalogOffering?.title || '---',
            code: courseCode,
            slug: premiumOffering.offering_slug,
            status: premiumOfferingStatus,
            modality: Modality.PremVTClass,
            offering: {
              ...premiumOffering,
              enrollments,
              modality: Modality.PremVTClass,
            },
          };
        }
        return null;
      },
    );

    return extendedPremiumOfferings?.filter((p) => p !== null);
  }

  extendOfferingsForPremiumEnrollments(premiumOfferings) {
    const extendedEnrollments =
      premiumOfferings?.map((premiumOffering) => {
        // eslint-disable-next-line camelcase
        const splittedOfferingSlug = premiumOffering.offering_slug.split('-');
        const courseCode = splittedOfferingSlug[0];
        const courseVersion = splittedOfferingSlug[1];

        // the environment might not have an offering in the catalog
        // which results in dashboard not loading up
        // this check ignores unknown premvt sessions
        let catalogOffering =
          this.catalogStore.groupedCatalogEntries[courseCode] ||
          this.catalogStore.groupedAllCatalogEntries[courseCode] ||
          {};
        catalogOffering =
          catalogOffering[courseVersion] ||
          (Object.values(catalogOffering)?.length > 0 &&
            Object.values(catalogOffering)[0]) ||
          {};

        const enrollments =
          this.classesStore.groupedPremiumSessionEnrollmentsByOffering[
            premiumOffering.offering_slug
          ];

        return enrollments?.map((enrollment) => {
          const scheduledSession = premiumOffering?.scheduledSessions?.find(
            (ss) => ss.doc_id === enrollment.premvt_session_uuid,
          );
          // last access date for each offering, descending
          // eslint-disable-next-line camelcase
          const sortedEnrollmentsbyLastAccessDate =
            enrollment?.state_transition_log?.filter(
              (log) => log.new_state === enrollment.state,
            );

          const lastAccessedDate =
            sortedEnrollmentsbyLastAccessDate?.length > 0
              ? sortedEnrollmentsbyLastAccessDate[0]?.timestamp
              : new Date();

          // get session title
          const splittedSessionSlug =
            scheduledSession.premvt_session_slug.split('-');
          const sessionContentMeta =
            (splittedSessionSlug?.length > 2 &&
              premiumOffering?.sessions?.find(
                (s) => s.slug === splittedSessionSlug[2],
              )) ||
            {};

          return {
            '@timestamp': lastAccessedDate,

            course_code: courseCode,
            course_slug: premiumOffering.offering_slug,
            title: catalogOffering?.title || '---',
            code: courseCode,
            slug: premiumOffering.offering_slug,
            status:
              enrollment.state === 'completed'
                ? 'attended'
                : 'scheduled_premium',
            modality: Modality.PremVTEnrollment,
            offering: {
              modality: Modality.PremVTEnrollment,
              ...enrollment,
              scheduledSession,
              course_code: courseCode,
              course_slug: premiumOffering.offering_slug,
              title: catalogOffering?.title || '---',
              sessionContent: premiumOffering,
              sessionTitle: sessionContentMeta?.title || '---',
            },
          };
        });
      }) || null;

    return extendedEnrollments?.filter((e) => e !== undefined)?.flat() || [];
  }

  @computed get offerings() {
    return this.hasCatalogData || this.hasRelatedData
      ? this.mergeOfferings.slice()
      : [];
  }

  // Alacarte user:  Merge dataset for course and exam. Unify data model by extending offering.
  // Non Alacarte user:  get dataset from catalogstore
  // Both users : sort data by title .
  @computed get mergeOfferings() {
    let entries = [];
    if (this.userStore.isAlaCarte) {
      entries = merge(
        toJS(this.catalogStore.groupedAlaCarteEnrollments).map((d) =>
          merge({ '@timestamp': d.updated }, d),
        ),
        this.labs,
      );
    } else {
      const mergedOfferings = merge(
        this.progressStore.progressByCourse,
        this.examsStore.exams,
      );
      const premiumOfferings =
        this.premiumSessionsStore.premiumContentWithScheduledSessions?.filter(
          (o) => o.scheduledSessions?.length > 0,
        );

      entries = [].concat(
        this.extendOfferings(mergedOfferings),
        this.extendOfferings(toJS(this.labs)),
        this.extendOfferingsForPremiumSessions(premiumOfferings),
        this.extendOfferingsForPremiumEnrollments(premiumOfferings),
      );
    }

    return entries;
  }

  @action getLabHoursWithCourse = async () => {
    // do we want to do this?
    // if (this.labs.length && !force) {
    //   return this.labs;
    // }

    try {
      const labsInfo = await getLabUsedHoursGroupByCourse();
      if (labsInfo) {
        this.labs = labsInfo.items;
      }
    } catch (error) {
      console.error(error);
    }
  };

  getOffering = (progressRecord) => {
    let offeringByType;

    if (progressRecord.doc_type === 'user_exam') {
      const [code] = progressRecord.exam_slug.split('-');
      const offeringObjects = this.catalogStore.subscriptionCatalog.filter(
        (item) => item.code === code,
      );
      return offeringObjects;
    }

    if (progressRecord.course_slug) {
      offeringByType = this.getOfferingDetails(progressRecord.course_code);
      // todo: dont want to calculate progress twice however progress is needed to filterbystatus
      if (offeringByType) {
        offeringByType = Object.values(offeringByType).map((o) => {
          const _progress = o?.code
            ? this.getProgress(o)
            : this.getProgress(o?.videoClassroom);
          o = {
            ...o,
            total_progress: _progress,
          };
          return o;
        });
      }
    }

    if (progressRecord.modality === Modality.Lab) {
      offeringByType = this.getLabDetails(progressRecord);
    }

    return offeringByType;
  };

  getProgress(offering) {
    if (!offering?.code && !offering?.version) return 0;

    const rolSlug = `${offering.code}-${offering.version}`;
    const vcSlug = `${offering.code}vc-${offering.version}`;
    const rolProgress =
      // eslint-disable-next-line camelcase
      this.progressStore.progressDict[rolSlug]?.total_progress || 0;
    const vcProgress =
      // eslint-disable-next-line camelcase
      this.progressStore.progressDict[vcSlug]?.total_progress || 0;
    return Math.max(rolProgress, vcProgress);
  }

  getLabDetails(progressRecord) {
    const [code, version] = progressRecord.offering_slug.split('-');
    let labDetails;

    const offeringDetails = this.getOfferingDetails(code);
    if (offeringDetails) {
      labDetails = progressRecord;
      labDetails = {
        ...labDetails,
        title: offeringDetails ? Object.values(offeringDetails)[0].title : '',
        code,
        version,
      };
    }
    return labDetails;
  }

  getOfferingDetails(code) {
    const offering = this.catalogStore.groupedCatalogEntries[code];

    if (!offering) {
      return null;
    }

    // all progresses for this code
    const offeringProgresses = this.progressStore.coursesInProgress.filter(
      (courseProgress) => courseProgress.course_code === code,
    );

    // filter all progresses for this code to find the ones missing from the Catalog
    const allVersionsNotInCatalog = offeringProgresses?.filter(
      (offeringProgress) => {
        return !Object.keys(offering).includes(
          offeringProgress.course_slug.split('-')[1],
        );
      },
    );

    if (allVersionsNotInCatalog?.length > 0) {
      // get retired offering from catalog_entries
      const retiredOffering = this.catalogStore.groupedAllCatalogEntries[code];

      allVersionsNotInCatalog.forEach((course) => {
        const versionNotInCatalog = course.course_slug.split('-')[1];

        const retiredOfferingAtVersion = retiredOffering[versionNotInCatalog];

        if (retiredOfferingAtVersion) {
          offering[versionNotInCatalog] = retiredOfferingAtVersion;
        } else if (
          !course?.course_slug?.includes('vc') &&
          !course?.course_slug?.includes('ea')
        ) {
          reportError(
            new Error(
              `Could not get offering details: version ${versionNotInCatalog} not present in catalog_entries`,
            ),
            { course_slug: course?.course_slug },
            'getOfferingDetails',
          );
        }
      });
    }

    return offering;
  }

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return (
      Math.ceil(this.filteredOfferings.length / this.entriesPerPage, 6) || 1
    );
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.filteredOfferings.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }
}

export default OfferingsListStore;
