import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { observer } from 'mobx-react';
import CatalogCard from 'components/Catalog/Card';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import CourseStore from '../store';

type Props = {
  courseStore: CourseStore;
};

const LabPlusTab = (props: Props) => {
  const { courseStore } = props;
  return courseStore.labPlusCourses.code.map((labPlusCode, offeringIndex) => {
    const offering = courseStore.catalogStore.uniqueCatalogEntries[labPlusCode];
    if (offering) {
      return (
        <div className="lab-plus-tab-content">
          <CatalogCard
            course={offering}
            // eslint-disable-next-line react/no-array-index-key
            key={`lab-plus-card-${labPlusCode}-${offeringIndex}`}
          />
        </div>
      );
    }
    return null;
  });
};

export default withErrorBoundary(
  observer(LabPlusTab),
  ComponentError,
  handleError,
);
