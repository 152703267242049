import { get, post, patch, put, delete as deleteRequest } from 'axios';
import {
  GENERATED_REPORT_API_BASE,
  MANAGEMENT_REPORTING_API_BASE,
  SCHEDULED_REPORT_JOB_MANAGEMENT_API_BASE,
  SCHEDULED_REPORT_JOB_REPORTS_MANAGEMENT_API_BASE,
} from '../config/constants';

export const getManagers = async (orgId) => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/managers`;
  const res = await get(url);

  return res.data.items;
};

export const getUsers = async (organization) => {
  try {
    const response = await get(
      `${MANAGEMENT_REPORTING_API_BASE}/${organization}/subscriptions`,
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getManagerReportees = async () => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}/manager/subscriptions?manager_id=me`;
  try {
    const response = await get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Attempts to add a new organization to the
 * Request body example:
 * "org_doc_data" : { "name": string, "order_number_set": [] }
 */
export const addOrganization = async (request) => {
  try {
    const response = await post(`${MANAGEMENT_REPORTING_API_BASE}`, request);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteOrganization = async (uuid) => {
  try {
    const response = await deleteRequest(
      `${MANAGEMENT_REPORTING_API_BASE}/${uuid}`,
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateOrganization = async (uuid, data) => {
  try {
    const response = await patch(
      `${MANAGEMENT_REPORTING_API_BASE}/${uuid}`,
      data,
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

/**
 * Updates list of user subscriptions to assign or unassign a manager
 * Request body example:
 * assign { "manager_id" : "id", "users" : ["user1","user2"] }
 */
export const assignManager = (organization, request) => {
  try {
    const response = patch(
      `${MANAGEMENT_REPORTING_API_BASE}/${organization}/subscriptions`,
      request,
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const setManagerState = async (orgId, managerId, newState) => {
  try {
    const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/managers`;
    const data = {
      update: [{ uuid: `${managerId}`, new_state: `${newState}` }],
    };

    const response = await patch(url, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

// invites manager on backend with default "pending" state
export const addManager = async (username, email, orgId) => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/managers`;
  const data = {
    username: `${username}`,
    email,
  };

  const response = await post(url, data);
  return response;
};

export const deleteManager = async (uuid, orgId) => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/managers`;
  const data = { remove: [uuid] };

  const response = await patch(url, data);
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  return response.data;
};

export const acceptPendingManager = async (uuid, orgId) => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/managers`;
  const data = { accept: [`${uuid}`] };

  const response = await patch(url, data);
  return response;
};

export const rejectPendingManager = async (uuid, orgId) => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/managers`;
  const data = { reject: [`${uuid}`] };

  const response = await patch(url, data);
  return response;
};
/**
 * Gets organization docs for a particular user based on UUID
 * @return {Array} An array of strings
 */
export const getOrganizations = async (uuid) => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}?user_uuid=${uuid}`;
  const res = await get(url);

  return res.data;
};

export const getAllOrganizations = async () => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}`;
  const res = await get(url);

  return res.data.managing;
};

export const getOrganizationSummary = async (
  orgId,
  subscription = 'all',
  summaryOf = '',
) => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/summary_dashboard/`;
  const params = { subscription };

  if (summaryOf) {
    // eslint-disable-next-line camelcase
    params.summary_of = summaryOf;
  }
  const res = await get(url, {
    params,
  });

  return { ...res.data };
};

export const getUserSubscriptionsByOrganizationIds = async (data) => {
  const url = `${MANAGEMENT_REPORTING_API_BASE}/student_subscriptions/`;
  const res = await post(url, data);

  return res.data;
};

export const getLearningPathsForOrgLeader = async (data) => {
  try {
    const url = `${MANAGEMENT_REPORTING_API_BASE}/leader_learning_paths/`;
    const res = await get(url, data);

    return res.data?.items;
  } catch (e) {
    throw new Error(e);
  }
};

export const getCustomSkillPaths = async (orgId) => {
  try {
    const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/custom_learning_paths`;
    const res = await get(url);

    return res.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getUserProgressForACustomSkillPath = async (
  orgId,
  customSkillPathId,
) => {
  try {
    const data = { learning_path: customSkillPathId };
    const url = `${MANAGEMENT_REPORTING_API_BASE}/${orgId}/custom_learning_paths`;
    const res = await post(url, data);

    return res.data;
  } catch (e) {
    throw new Error(e);
  }
};

// ====== admin scheduled jobs

export const getScheduledJobs = async (organisationId, role) => {
  const { data } = await get(`${SCHEDULED_REPORT_JOB_MANAGEMENT_API_BASE}`, {
    params: {
      organisationId,
      role,
    },
  });
  return data;
};

export const getScheduledJobById = (jobId) => {
  return get(`${SCHEDULED_REPORT_JOB_MANAGEMENT_API_BASE}${jobId}`);
};

export const createScheduledJob = async (job, organizationId) => {
  const { data } = await post(`${SCHEDULED_REPORT_JOB_MANAGEMENT_API_BASE}`, {
    ...job,
    organizationId,
  });
  return data;
};

export const editScheduledJob = (jobId, payload) => {
  return put(`${SCHEDULED_REPORT_JOB_MANAGEMENT_API_BASE}${jobId}`, payload);
};

export const submitActionForScheduleJobs = (actionItems, action) => {
  const items = Array.isArray(actionItems) ? actionItems : [actionItems];
  return patch(SCHEDULED_REPORT_JOB_MANAGEMENT_API_BASE, { action, items });
};

export const deleteScheduledJob = (jobIds) => {
  const listToDelete = Array.isArray(jobIds) ? jobIds : [jobIds];
  const actionItems = listToDelete.map((id) => ({
    id,
    cancelled: true,
  }));
  return submitActionForScheduleJobs(actionItems, 'cancel_jobs');
};

export const getScheduledJobReports = async (jobId) => {
  const { data } = await get(
    `${SCHEDULED_REPORT_JOB_REPORTS_MANAGEMENT_API_BASE}`,
    {
      params: {
        scheduled_report_id: jobId,
      },
    },
  );
  return data;
};

// ====== admin reports

export const getReports = async (organisationId, role, from, size) => {
  const { data } = await get(`${GENERATED_REPORT_API_BASE}`, {
    params: {
      organisationId,
      role,
      from,
      size,
    },
  });
  return data?.items || [];
};

export const getReportById = (reportId) => {
  return get(`${GENERATED_REPORT_API_BASE}${reportId}`);
};

export const deleteReport = (reportIds) => {
  const listToDelete = Array.isArray(reportIds) ? reportIds : [reportIds];

  return put(
    `${GENERATED_REPORT_API_BASE}`,
    listToDelete.map((id) => ({
      doc_id: id,
      receipt: 'deleted',
    })),
  );
};
