import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-mobx-router5';
import { storesContext } from 'stores';
import PropTypes from 'prop-types';
import { handleError } from 'services/ErrorService';
import ComponentError from 'components/Error/ComponentError';
import './styles.scss';

const Favorites = ({ bookmarkList, onBookmark }) => {
  const { routerStore } = useContext(storesContext);
  const { route } = routerStore;
  const { t } = useTranslation();

  return (
    <fieldset id="tab-course-bookmarks">
      <legend>{t('Course Bookmarks')}</legend>
      <div className="favorite-list">
        {Array.isArray(bookmarkList) && bookmarkList.length ? (
          bookmarkList.map((bookmark) => (
            <div className="favorite-item" key={bookmark.section}>
              <Link
                href
                routeName="courses:page"
                routeParams={{
                  course: route.params.course,
                  page: bookmark.section,
                }}
              >
                {t(`${bookmark.title}`)}
              </Link>
              <span
                role="button"
                tabIndex={0}
                className="glyphicon glyphicon-trash trash-icon"
                onClick={() => onBookmark(bookmark.section)}
              />
            </div>
          ))
        ) : (
          <React.Fragment>
            <div>
              {t(
                'No bookmarks have been added. Try adding one by clicking the',
              )}{' '}
              <span className="favorite-icon glyphicon glyphicon-star-empty" />{' '}
              {t('icon next to a page title in the Course.')}
            </div>
          </React.Fragment>
        )}
      </div>
    </fieldset>
  );
};

Favorites.propTypes = {
  bookmarkList: PropTypes.array,
  onBookmark: PropTypes.func.isRequired,
};

Favorites.defaultProps = {
  bookmarkList: [],
};

export default withErrorBoundary(
  observer(Favorites),
  ComponentError,
  handleError,
);
