import React from 'react';
import { Text } from '@patternfly/react-core';
import {
  CourseMetadata,
  CourseRelatedContent,
  CourseRelationType,
} from 'types';
import filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-mobx-router5';
import useMobxStores from 'hooks/useMobxStores';

type Props = {
  relatedContent: CourseMetadata['related_contents'];
};

type CourseRelatedContentWithEntry = CourseRelatedContent & {
  catalogEntry: any;
};

const RelatedContent = ({ relatedContent }: Props) => {
  const { t } = useTranslation();
  const { catalogStore } = useMobxStores();

  const childOfItems = filter<CourseRelatedContent>(
    relatedContent,
    (entry: CourseRelatedContent) =>
      entry?.relation_type === CourseRelationType.ChildOf,
  );

  const itemsWithCatalogEntries: CourseRelatedContentWithEntry[] =
    childOfItems.map((item) => {
      const catalogEntry =
        catalogStore.groupedCatalogEntries?.[item.code]?.[item.version];

      return { ...item, catalogEntry };
    });

  return (
    <>
      <Text>
        {t(
          'This lesson is a component of one or multiple offerings. Completing this lesson contributes to the progress in the following offerings:',
        )}
      </Text>
      <ul className="course-overview__list">
        {itemsWithCatalogEntries.map((item) => {
          let title = '';
          if (!item?.catalogEntry) {
            title = `${item.code}-${item.version}`.toUpperCase();
          } else {
            title = `${item.catalogEntry?.slug?.toUpperCase?.()} - ${
              item.catalogEntry.title || ''
            }`;
          }

          return (
            <li key={`${item.code}-${item.version}`}>
              <Link
                href
                routeName="courses:view"
                routeParams={{
                  course: `${item.code}-${item.version}`,
                }}
              >
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
      <Text>
        {t(
          'Note: Course outline is subject to change with technology advances and as the nature of the underlying job evolves',
        )}
      </Text>
    </>
  );
};

export default RelatedContent;
