import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { when } from 'mobx';
import { observer } from 'mobx-react';

import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';

import { storesContext } from '../../stores';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import LearningPathList from '../../components/LearningPath/List';
import CatalogCardList from '../../components/Catalog/List';
import './styles.scss';
import ResultList from '../../components/Search/ResultList';

const Search = () => {
  const {
    catalogStore,
    learningPathStore,
    routerStore,
    searchStore,
    userStore,
  } = useContext(storesContext);
  const { t } = useTranslation();
  const { route } = routerStore;
  const { q: query } = route.params;

  useEffect(() => {
    searchStore.query = query;
    try {
      searchStore.search(query);
    } catch (error) {
      console.error(error);
    }
    when(
      () => catalogStore.entries.length && !userStore.isAlaCarte,
      () => {
        catalogStore.filterEntries();
      },
    );
    when(
      () => learningPathStore.entries.length && !userStore.isAlaCarte,
      () => {
        learningPathStore.filterEntries();
      },
    );
    // when component is about to be destroyed, clear the search && reset
    // catalog & learning path results
    // Update preset filters when route changes
  }, [query, route]);

  useEffect(() => {
    return () => {
      searchStore.query = '';
    };
  }, [route.name]);

  return (
    <div className="container-grid-responsive">
      <Row>
        <Col xs={12}>
          <Tabs defaultActiveKey={1} id="search-results-tabs">
            <Tab eventKey={1} title={t('All results').toUpperCase()}>
              <section className="all-search-results-container">
                <ResultList />
              </section>
            </Tab>
            {!userStore.isAlaCarte && !userStore.isExternalSubscriber && (
              <Tab eventKey={2} title={t('Catalog').toUpperCase()}>
                <section className="catalog-search-results-container">
                  <CatalogCardList />
                </section>
              </Tab>
            )}
            {!userStore.isAlaCarte && !userStore.isExternalSubscriber && (
              <Tab eventKey={3} title={t('Skills Paths').toUpperCase()}>
                <section className="learning-paths-search-results-container">
                  <LearningPathList />
                </section>
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default withErrorBoundary(
  observer(Search),
  GenericViewError,
  handleError,
);
