import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import PropTypes from 'prop-types';
import { Link } from 'react-mobx-router5';
import { Button } from '@patternfly/react-core';
import { Panel } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { v4 as uuidv4 } from 'uuid';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useTranslation } from 'react-i18next';
import PremiumClassIcon from 'img/icons/live-session-indicator.svg';
import BaseCard from 'components/Card/index';
import ViewLoader from 'components/Loading/View';
import GroupCirclesIndicator from 'components/MyProgress/GroupCirclesIndicator';
import { getCourseTitleByLanguage } from 'services/UtilityService';
import { storesContext } from 'stores';
import ScheduleAllModal from '../ScheduleAllModal';
import Store from './store';
import SessionCard from './Session';
import SessionCollateralStore from './Session/store';
import './styles.scss';

const PremiumClassCard = (props) => {
  const { i18n, t } = useTranslation();
  const [courseInfoOpened, setCourseInfoOpened] = React.useState(false);

  const {
    coloredBottom,
    enrollments,
    courseDetails,
    courseActions,
    progress,
    indicatorElementsArray,
    svgIconElement,
    actionToggle,
    lastAccessed,
    showCertificateButton,
    allowOrderingOffering,
    onOfferingOrderChange,
  } = props;
  const {
    catalogStore,
    classesStore,
    routerStore,
    userStore,
    premiumSessionFilterStore,
  } = useContext(storesContext);

  const store = useLocalStore(() => new Store(classesStore));

  const sessionCollateralStore = useLocalStore(
    () => new SessionCollateralStore(),
  );

  useEffect(() => {
    store.premiumClass = props.premiumClass;

    store.enrollments = props.enrollments;

    store.externallyExpanded = props.externallyExpanded;
    store.ready = true;
  }, [props]);

  const courseCode = store.offeringSlug.split('-')[0];

  let title = getCourseTitleByLanguage(
    catalogStore.uniqueCatalogEntries[courseCode]?.translations,
    i18n.language,
  );

  // if the title was not found on the catalog, load the collateral
  if (!title && store.sessions.length) {
    sessionCollateralStore.getSessionCollateral(
      `${store.offeringSlug}-${store.sessions[0].slug}`,
      i18n.language,
    );
    title = sessionCollateralStore.collateral.offering_title;
  }

  const cardTitle = title
    ? `${courseCode.toUpperCase()} - ${title}`
    : `${courseCode.toUpperCase()}`;

  const cardColor = '#007A87';

  const renderSessionCard = (
    course,
    premvtSessionSlug,
    sessionContent,
    enrollment,
    scheduledSession,
    sessionToSchedule, // Session to display for Schedule All feature
    scheduleAllMode = false,
    key = uuidv4(),
  ) => {
    return (
      <SessionCard
        course={course}
        premvt_session_slug={premvtSessionSlug}
        sessionContent={sessionContent}
        enrollment={enrollment}
        scheduledSession={scheduledSession}
        key={key}
        onCancelSessionSuccess={() => {
          store.externallyExpanded = false;
        }}
        scheduleAllMode={scheduleAllMode}
        sessionToSchedule={sessionToSchedule}
        onIncludeSession={
          sessionToSchedule &&
          ((checked, session) =>
            store.addIncludedSessionToSchedule(checked, session))
        }
        isIncluded={
          sessionToSchedule ? store.isSessionIncluded(sessionToSchedule) : false
        }
        sessionModality={store.sessionModality}
      />
    );
  };

  const renderElementCard = (session, index) => {
    const sessionSlug = `${store.offeringSlug}-${session.slug}`;
    const enrollment = store.enrollmentForSessionSlug(sessionSlug);
    const scheduledSession = store.getScheduledSessionForEnrollment(
      enrollment,
      sessionSlug,
    );

    const scheduleAllSession = store.scheduleAllMode
      ? store.getScheduleAllSessionForSessionSlug(sessionSlug)
      : undefined;
    return (
      <VerticalTimelineElement
        className={`vertical-timeline-element--work ${
          enrollment?.state === 'completed' ? `green` : ''
        }`}
        key={index}
      >
        {!store.scheduleAllMode
          ? renderSessionCard(
              {
                name: title,
                code: courseCode.toUpperCase(),
                offering_slug: store.offeringSlug,
              },
              sessionSlug,
              session,
              enrollment,
              scheduledSession,
            )
          : renderSessionCard(
              {
                name: title,
                code: courseCode.toUpperCase(),
                offering_slug: store.offeringSlug,
              },
              sessionSlug,
              session,
              enrollment,
              scheduledSession,
              scheduleAllSession,
              true,
            )}
      </VerticalTimelineElement>
    );
  };

  const renderClassInfo = () => {
    const scheduledSessions = enrollments.filter(
      (session) => session.state === 'enrolled',
    );
    const attendedSessions = enrollments.filter(
      (session) => session.state === 'completed',
    );
    return (
      <React.Fragment>
        <p>
          <b>{t('Number of sessions:')}</b> <span>{store.sessions.length}</span>
        </p>
        <p>
          <b>{t('Scheduled:')}</b> <span>{scheduledSessions.length}</span>
        </p>
        <p>
          <b>{t('Attended:')}</b> <span>{attendedSessions.length}</span>
        </p>
      </React.Fragment>
    );
  };

  const renderSessionsStateIcons = () => [
    <GroupCirclesIndicator
      key={uuidv4()}
      sessionContentSessions={store.premiumClass.sessions}
      enrollments={store.enrollments}
      scheduledSessions={premiumSessionFilterStore.filteredEntries}
      offeringSlug={store.premiumClass.offering_slug}
    />,
  ];

  const actionButtonsArray = [
    showCertificateButton && (
      <Link
        href
        key={uuidv4()}
        routeName="premium:sessions:coa"
        routeParams={{
          offeringslug: store.offeringSlug,
        }}
      >
        {t('View Certificate')}
      </Link>
    ),
    (!courseDetails || store.externallyExpanded) &&
      (userStore.isFreeTierSubscriber ||
      userStore.hasReachedCurrentSubscriptionEnrollPremiumVTLimit ||
      userStore.hasReachedCurrentSubscriptionPremiumVTLimit ? (
        <Button
          variant="link"
          isDisabled
          data-analytics-id="req-btn-session-card-freeTier-lp"
          className="disabled-patternfly-btn"
        >
          {t('Schedule all').toUpperCase()}
        </Button>
      ) : (
        <Button
          key={uuidv4()}
          data-analytics-id={
            store.scheduleAllMode
              ? 'cancel-btn-session-card-lp'
              : 'schedule-all-btn-session-card-lp'
          }
          disabled={store.isScheduleAllLoading}
          onClick={() => {
            if (store.externallyExpanded && store.scheduleAllMode) {
              store.externallyExpanded = false;
              actionToggle();
            }

            store.toggleScheduleAllMode();
          }}
        >
          {t(store.scheduleAllMode ? 'Cancel' : 'Schedule all')}
        </Button>
      )),
    store.scheduleAllMode && (
      <Button
        key={uuidv4()}
        disabled={
          !store.isEnabledConfirmForScheduleAll || store.isScheduleAllLoading
        }
        className={
          !store.isEnabledConfirmForScheduleAll || store.isScheduleAllLoading
            ? 'disabled-patternfly-btn'
            : ''
        }
        onClick={async () => {
          if (store.canScheduleAllSelectedSessions) {
            await store.enrollUserIncludedPremiumSessions();
            routerStore.navigate('home');
          } else {
            store.displayScheduleAllModal = true;
          }
        }}
      >
        {!store.isScheduleAllLoading ? t('Confirm') : <ViewLoader />}
      </Button>
    ),
  ];

  return (
    store.ready && (
      <React.Fragment>
        <BaseCard
          data-analytics-id="premium-course-card-liveSess-lp"
          renderChildrenByDefault={false}
          customClassName="premiumvt-class"
          cardColor={cardColor}
          coloredBottom={coloredBottom}
          svgIconElement={
            svgIconElement || (
              <SVG src={PremiumClassIcon} title={t('Premium Class')} />
            )
          }
          title={cardTitle}
          firstLineElement={renderClassInfo()}
          progress={progress}
          indicatorElementsArray={
            indicatorElementsArray || renderSessionsStateIcons()
          }
          actionButtonsArray={
            courseActions.length === 0 || store.scheduleAllMode
              ? actionButtonsArray
              : actionButtonsArray.concat(courseActions)
          }
          externallyExpanded={store.externallyExpanded}
          actionToggle={() => {
            actionToggle();
            if (store.scheduleAllMode) store.toggleScheduleAllMode();
          }}
          leftPanelElement={lastAccessed && <span>{lastAccessed}</span>}
          allowOrderingOffering={allowOrderingOffering}
          onOfferingOrderChange={onOfferingOrderChange}
        >
          {courseDetails?.element && courseDetails?.toggle && (
            <Panel
              className="premium-class-course-info"
              expanded={courseInfoOpened}
              onToggle={() => {
                setCourseInfoOpened(!courseInfoOpened);
              }}
            >
              <Panel.Heading id="course-details-head">
                <Panel.Title id="course-details-title">
                  {t('Course Details').toUpperCase()}
                </Panel.Title>
                <Panel.Toggle
                  componentClass={Button}
                  id="course-details-button"
                  bsStyle="link"
                  onClick={(event) => {
                    event.stopPropagation();
                    courseDetails.toggle();
                  }}
                >
                  ...
                </Panel.Toggle>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>{courseDetails.element}</Panel.Body>
              </Panel.Collapse>
            </Panel>
          )}

          <div className="premium-class-elements">
            <VerticalTimeline layout="1-column">
              {store.sessions.map((el, index) => {
                return renderElementCard(el, index);
              })}
            </VerticalTimeline>
          </div>
        </BaseCard>
        {store.scheduleAllMode && (
          <ScheduleAllModal
            show={store.displayScheduleAllModal}
            onCloseModal={() => {
              store.displayScheduleAllModal = false;
            }}
          />
        )}
      </React.Fragment>
    )
  );
};

PremiumClassCard.propTypes = {
  premiumClass: PropTypes.object,
  coloredBottom: PropTypes.bool,
  enrollments: PropTypes.array,
  courseDetails: PropTypes.any,
  courseActions: PropTypes.array,
  progress: PropTypes.number,
  indicatorElementsArray: PropTypes.any,
  svgIconElement: PropTypes.any,
  externallyExpanded: PropTypes.bool,
  actionToggle: PropTypes.func,
  lastAccessed: PropTypes.object,
  showCertificateButton: PropTypes.bool,
  allowOrderingOffering: PropTypes.bool,
  onOfferingOrderChange: PropTypes.func,
};

PremiumClassCard.defaultProps = {
  premiumClass: {},
  coloredBottom: false,
  enrollments: [],
  courseDetails: undefined,
  courseActions: [],
  progress: 0,
  indicatorElementsArray: undefined,
  svgIconElement: undefined,
  externallyExpanded: false,
  actionToggle: () => {},
  lastAccessed: undefined,
  showCertificateButton: false,
  allowOrderingOffering: false,
  onOfferingOrderChange: () => {},
};

export default observer(PremiumClassCard);
