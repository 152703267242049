import React, { useEffect } from 'react';
import { Switch } from '@patternfly/react-core';
import { useFeature } from '@ole-ui/ole-ui-components';
import useMobxStores from 'hooks/useMobxStores';
import { observer } from 'mobx-react';
import CourseView from './CourseViewV2';
import LegacyCourseView from './CourseViewV1/LegacyCourseView';

const CourseViewSwitcher = () => {
  const { userStore } = useMobxStores();
  const isCourseHUDEnabled = useFeature({
    section: 'course',
    flagName: 'hud_enabled',
    defaultFlagValue: false,
  });

  const isHUDEnabledByUser =
    userStore.userPreferences.active_course_view === 'hud';

  useEffect(() => {
    window.scrollTo?.({ top: 0 });
  }, []);

  const View =
    isCourseHUDEnabled && isHUDEnabledByUser ? CourseView : LegacyCourseView;

  const isLocalhost =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  const handleChange = (
    _event: React.FormEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    userStore.updateUserPreferences({
      active_course_view: checked ? 'hud' : 'legacy',
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      {isCourseHUDEnabled && isLocalhost && (
        <div
          style={{
            position: 'absolute',
            left: '10px',
            top: '90px',
            zIndex: '4',
            padding: '2px',
          }}
        >
          <Switch
            id="simple-switch"
            label="HUD"
            //   labelOff="HUD experience"
            isChecked={isHUDEnabledByUser}
            onChange={handleChange}
            ouiaId="BasicSwitch"
          />
        </div>
      )}
      <View />
    </div>
  );
};

export default observer(CourseViewSwitcher);
