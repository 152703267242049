import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { when } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-mobx-router5';
import { Alert } from '@patternfly/react-core';
import { observer, useLocalStore } from 'mobx-react';
import {
  Table,
  Panel,
  Glyphicon,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import CollapseIcon from '../../img/icons/web-icon-caret-thin-up-white.svg';
import ExpandIcon from '../../img/icons/web-icon-caret-thin-down-white.svg';
import GroupCirclesIndicator from '../MyProgress/GroupCirclesIndicator';
import InstructorName from './InstructorName';
import SessionDetailsStore from './store';
import SessionCollateral from '../Classes/PremiumClasses/Card/Session/SessionCollateral';
import RequestSessionForm from './RequestSessionForm';
import SessionCollateralStore from '../Classes/PremiumClasses/Card/Session/store';
import { storesContext } from '../../stores';
import { getCourseTitleByLanguage } from '../../services/UtilityService';
import './styles.scss';

const SessionDetails = (props) => {
  const { data, showMetaData, showProgress, isRequestingSession } = props;

  const { i18n, t } = useTranslation();
  const locale = i18n.language || 'en';

  const isSchedulingPage = showMetaData;

  const { catalogStore, userStore, premiumSessionsStore, classesStore } =
    useContext(storesContext);

  const sessionCollateralStore = useLocalStore(
    () => new SessionCollateralStore(),
  );

  const store = useLocalStore(() => new SessionDetailsStore(data));

  const timezone = moment.tz.guess() || undefined;

  const handleToggle = () => {
    store.toggle();
  };

  const TimeInfoTooltip = (
    <Tooltip id="time-info-tooltip">
      {`${t('The start time is based on your time zone:')} ${timezone}`}
    </Tooltip>
  );

  useEffect(() => {
    if (!isSchedulingPage) {
      store.opened = false;
    }

    sessionCollateralStore.getSessionCollateral(
      data.premvt_session_slug,
      locale,
    );
  }, []);

  useEffect(() => {
    // Populate entry data and filters on initial load
    when(
      () => !catalogStore.loaded && userStore.subscription.subscription,
      async () => {
        await catalogStore.getCatalogEntries();

        catalogStore.loaded = true;
      },
    );

    when(
      () => catalogStore.loaded,

      () => {
        // eslint-disable-next-line camelcase
        const offeringSlug = data?.offering_slug?.split('-') || [];
        store.courseTitle =
          offeringSlug?.length > 1
            ? getCourseTitleByLanguage(
                catalogStore.uniqueCatalogEntries[offeringSlug[0]].translations,
                i18n.language,
              )
            : // eslint-disable-next-line camelcase
              data?.offering_slug || '---';
      },
    );
  }, [store]);

  return (
    <div id="session-details-wrapper">
      {userStore.isFreeTierSubscriber && (
        <Alert
          isInline
          variant="info"
          title={t('Upgrade to Premium Subscription to enable scheduling')}
          className="free-tier-alert"
        >
          <p>
            {t(
              'As a Free Subscription user, you can explore our scheduling feature, but scheduling is disabled. Upgrade to our Premium Subscription to access all the amazing features, including the ability to schedule.',
            )}
          </p>
        </Alert>
      )}
      <Panel expanded={store.opened} onToggle={handleToggle}>
        <Panel.Heading onClick={handleToggle}>
          <Panel.Title>
            <Table
              condensed
              hover
              className="session-dashboard-overview-table-head"
            >
              <thead>
                {/* Display the course code and name */}
                <tr className="session-name">
                  <td className="course-title-wrapper">{`${
                    showMetaData
                      ? store.courseTitle
                      : store.headerTitleScheduleSession
                  }`}</td>
                  <td className="course-expand">
                    <SVG src={store.opened ? CollapseIcon : ExpandIcon}>
                      {store.opened ? t('Collapse') : t('Expand')}
                    </SVG>
                  </td>
                </tr>
              </thead>
            </Table>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Collapse>
          <Panel.Body>
            <Table
              striped
              bordered
              condensed
              hover
              className="session-dashboard-overview-table"
            >
              <tbody>
                {/* Display request session form */}
                {isRequestingSession && (
                  <RequestSessionForm
                    offeringSlug={data.offering_slug}
                    premVtSessionSlug={data.premvt_session_slug}
                    interest={classesStore.getInterestInSession(
                      data.premvt_session_slug,
                    )}
                  />
                )}
                {/* Display session start time */}
                {isSchedulingPage && !isRequestingSession && (
                  <tr>
                    <td>
                      {t('Starts')}
                      <OverlayTrigger placement="top" overlay={TimeInfoTooltip}>
                        <span className="with-info-icon">
                          <Glyphicon
                            glyph="info-sign"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <Moment
                        locale={locale}
                        title={data.start_time}
                        {...(timezone && {
                          tz: timezone,
                        })}
                        format="dddd, MMM DD YYYY, hh:mm A Z z"
                      >
                        {data.start_time}
                      </Moment>
                      <br />
                      <p className="attend-info">
                        {t(
                          `Attending a session is available from 15 minutes prior to scheduled start time up until it's marked completed by the instructor.`,
                        )}
                      </p>
                    </td>
                  </tr>
                )}
                {/* Display duration */}
                {!isRequestingSession && (
                  <tr>
                    <td>{t('Duration')}</td>
                    <td>
                      {data.duration_secs
                        ? `${t(data.duration_secs / 3600)} ${t('hours')}`
                        : t('No info')}
                    </td>
                  </tr>
                )}

                {/* Display seats available */}
                {isSchedulingPage && !isRequestingSession && (
                  <tr className="seats">
                    <td>{t('Seats')} </td>
                    <td>
                      {!userStore.isInstructorOrAdmin
                        ? t(
                            premiumSessionsStore.convertAvailableSeatsToRange(
                              data.seats_available,
                            ),
                          )
                        : t(`${data.seats_available}`)}
                    </td>
                  </tr>
                )}
                {/* Display the Instructors names */}
                {isSchedulingPage && !isRequestingSession && (
                  <tr className="instructors">
                    <td>{t('Instructor(s)')}</td>
                    <td>
                      {data?.instructors?.map((instructor) => {
                        return (
                          <p key={uuidv4()}>
                            <InstructorName
                              instructor={premiumSessionsStore.getDetailsForInstructor(
                                instructor,
                              )}
                            />
                          </p>
                        );
                      })}
                    </td>
                  </tr>
                )}
                {/* Display Language */}
                {isSchedulingPage && !isRequestingSession && (
                  <tr className="language">
                    <td>{t('Language')}</td>
                    <td>{data.instruction_language}</td>
                  </tr>
                )}
                {/* Display Chapters */}
                <tr className="collateral">
                  <td>{t('Chapters')}</td>
                  <td>
                    <SessionCollateral
                      collateral={sessionCollateralStore.collateral}
                      loading={sessionCollateralStore.loadingCollateral}
                      showChaptersTitle={false}
                    />
                  </td>
                </tr>
                {/* Display Session Notes */}
                {!isRequestingSession && (
                  <tr className="session-notes">
                    <td>{t('Important Notes')}</td>
                    <td>
                      <SessionNotes
                        notes={sessionCollateralStore.collateral?.notes}
                        courseSlug={data.offering_slug}
                        durationHours={
                          data.duration_secs
                            ? Math.ceil(data.duration_secs / 3600)
                            : undefined
                        }
                      />
                    </td>
                  </tr>
                )}
                {/* Display Progress */}
                {showProgress && (
                  <tr className="progress-of-course">
                    <td>{t('Course Progress')}</td>
                    <td className="progress-indicators">
                      <GroupCirclesIndicator
                        key={1}
                        sessionContentSessions={
                          premiumSessionsStore
                            .groupedPremiumSessionContentByOffering[
                            data.offering_slug
                          ]?.sessions || []
                        }
                        enrollments={
                          classesStore
                            .groupedPremiumSessionEnrollmentsByOffering[
                            data.offering_slug
                          ] || []
                        }
                        scheduledSessions={
                          premiumSessionsStore
                            .groupedScheduledSessionsByOffering[
                            data.offering_slug
                          ]
                        }
                        offeringSlug={data.offering_slug}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    </div>
  );
};

SessionDetails.propTypes = {
  data: PropTypes.object,
  showMetaData: PropTypes.bool,
  showProgress: PropTypes.bool,
  isRequestingSession: PropTypes.bool,
};

SessionDetails.defaultProps = {
  data: {},
  showMetaData: true,
  showProgress: true,
  isRequestingSession: false,
};

const SessionNotes = ({ notes, courseSlug, durationHours }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {notes && (
        <div
          className="session-notes-body"
          dangerouslySetInnerHTML={{
            __html: notes.body,
          }}
        />
      )}
      <div className="common-notes">
        <ul>
          <li>
            {t(
              'Lab environments should be launched and created at least 30-45 minutes prior to course start time to ensure that the systems are ready for use and available at course start time. To launch your lab, click',
            )}{' '}
            <Link
              key={uuidv4()}
              href
              routeName="courses:page"
              routeParams={{
                course: courseSlug,
                page: 'pr01',
              }}
            >
              {t('here')}
            </Link>{' '}
            {t('to access the')} {courseSlug?.split('-')[0].toUpperCase()}{' '}
            {t('course, and select the "Lab Environment" tab.')}
          </li>
          <li>
            {t(
              `After the lab environment is in a running state, you should extend the "auto-stop" timer to ${
                durationHours ? durationHours + 1 : 4
              } hours so that it will last for the entire duration of the session plus a little bit longer.`,
            )}
          </li>
          <li>
            {t(
              'If you reset any machine in the lab environment, the auto-stop timer will reset back to the default time and will need to be extended again.',
            )}
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

SessionNotes.propTypes = {
  notes: PropTypes.object,
  courseSlug: PropTypes.string.isRequired,
  durationHours: PropTypes.number.isRequired,
};

SessionNotes.defaultProps = {
  notes: {},
};

export default observer(SessionDetails);
