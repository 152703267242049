import React, { useContext, useEffect } from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-mobx-router5';
import { useTranslation } from 'react-i18next';
import ReactDOMServer from 'react-dom/server';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { withErrorBoundary } from 'react-error-boundary';
import { KalturaWidget } from '@ole-ui/ole-ui-components';
import { KALTURA_CONFIGS } from 'config/constants';
import ShowSolution from './Interactions/ShowSolution';
import MultiChoice from './Interactions/Multichoice';
import ReSequencing from './Interactions/Resequencing';
import Matching from './Interactions/Matching';
import SectionLinks from './SectionLinks';
import ImagePreviewer from './ImagePreviewer';
import JwPlayer from './JwPlayer';
import { storesContext } from '../../../stores';
import './styles.scss';

const handleError = () => {
  // TODO: send error to backend analytics service
};

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t(
        "Sorry, we're having trouble displaying this page. Please check back later.",
      )}
    </div>
  );
};

const ContentArea = (props) => {
  const [page, setPage] = React.useState(props.page || {});
  useEffect(() => {
    // Changes the internal state `isExpanded` to true using an external input via props
    if (props.page && !props.pageInProgress) {
      setPage(props.page);
    }
  }, [props.page, props.pageInProgress]);
  const { content, language } = props;
  const { routerStore } = useContext(storesContext);
  const { route } = routerStore;

  const htmlParser = new DOMParser();

  const node = htmlParser.parseFromString(content, 'text/html');

  const html = node.body.innerHTML;

  return (
    <React.Fragment>
      {page.page_tag &&
        Parser(html, {
          replace(domNode) {
            const className =
              domNode && domNode.attribs && domNode.attribs.class;
            let data;
            let dataToMarkup;

            switch (className) {
              case 'jwplayer-injector':
                if (!domNode.attribs['data-id']) return null;

                return <JwPlayer id={domNode.attribs['data-id']} />;
              case 'kalturaplayer-injector':
                if (!domNode.attribs['data-id']) return null;

                return (
                  <div>
                    <KalturaWidget
                      className="expert-extras-player"
                      partnerId={KALTURA_CONFIGS.partnerId}
                      playerId={
                        KALTURA_CONFIGS.courseContentInlinePlayer.uiConfId
                      }
                      entryId={domNode.attribs['data-id']}
                      isPlaylist={
                        'data-isplaylist' in domNode.attribs &&
                        domNode.attribs['data-isplaylist'] !== 'false'
                      }
                    />
                  </div>
                );

              case 'MultChoice':
                data = domToReact(domNode.children);
                dataToMarkup = ReactDOMServer.renderToStaticMarkup(data);

                return (
                  <MultiChoice
                    data={dataToMarkup}
                    quizAttributeId={domNode.parent.children[0].attribs.id}
                    chapter={page.page_tag}
                  />
                );

              case 'procedure solution':
                data = domToReact(domNode.children);
                dataToMarkup = ReactDOMServer.renderToStaticMarkup(data);

                return (
                  <ShowSolution data={dataToMarkup} parser="withsolution" />
                );

              case 'procedure':
                data = domToReact(domNode.children);
                dataToMarkup = ReactDOMServer.renderToStaticMarkup(data);

                return <ShowSolution data={dataToMarkup} />;

              case 'Resequencing':
                data = domToReact(domNode.children);
                dataToMarkup = ReactDOMServer.renderToStaticMarkup(data);

                return (
                  <ReSequencing
                    data={dataToMarkup}
                    quizAttributeId={domNode.prev.prev.attribs.id}
                    chapter={page.page_tag}
                  />
                );

              case 'Matching':
                data = domToReact(domNode.children);
                dataToMarkup = ReactDOMServer.renderToStaticMarkup(data);

                return (
                  <Matching
                    data={dataToMarkup}
                    chapter={page.page_tag}
                    language={language}
                  />
                );

              case 'toc':
                if (domNode?.name !== 'dl') return null;

                data = domToReact(domNode.children);
                dataToMarkup = ReactDOMServer.renderToStaticMarkup(data);

                return <SectionLinks data={dataToMarkup} />;
              case 'xref':
                if (domNode?.name !== 'a') return null;
                if (!domNode.attribs?.href) return null;

                return (
                  <Link
                    href
                    routeName="courses:page"
                    routeParams={{
                      course: route.params.course,
                      page: domNode.attribs.href.replace(/.html.*$/, ''),
                    }}
                  >
                    {domToReact(domNode.children)}
                  </Link>
                );

              default: {
                if (domNode.name === 'img') {
                  data = domToReact([domNode]);
                  dataToMarkup = ReactDOMServer.renderToStaticMarkup(data);
                  return (
                    <ImagePreviewer data={dataToMarkup}>{data}</ImagePreviewer>
                  );
                }
                return null;
              }
            }
          },
        })}
    </React.Fragment>
  );
};

ContentArea.propTypes = {
  content: PropTypes.string,
  page: PropTypes.object.isRequired,
  language: PropTypes.string,
  pageInProgress: PropTypes.bool,
};

ContentArea.defaultProps = {
  content: '',
  language: 'en-US',
  pageInProgress: false,
};

export default withErrorBoundary(
  observer(ContentArea),
  ErrorMessage,
  handleError,
);
