import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@patternfly/react-core';
import { useFeature } from '@ole-ui/ole-ui-components';
import CertificateOfAttendanceButton from 'components/CertificateOfAttendance/Button';
import EBookDownloader from 'components/EBookDownloader';
import LanguageDropdown from 'components/Language/Dropdown';
import VersionsDropdown from 'components/Versions/Dropdown';
import BullHornIcon from '@patternfly/react-icons/dist/esm/icons/bullhorn-icon';
import { withErrorBoundary } from 'react-error-boundary';
import { observer } from 'mobx-react';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import useMobxStores from 'hooks/useMobxStores';

import CourseStore from './store';

type Props = {
  courseStore: CourseStore;
  courseSlug: string;
};

const CourseToolbar = ({ courseSlug, courseStore }: Props) => {
  const { t } = useTranslation();
  const { configStore, userStore, eventStore, routerStore } = useMobxStores();

  const {
    loadedInitialData,
    courseCode,
    courseVersion,
    page,
    canShowCertAttendanceButton,
    legacyAttendanceTimestamp,
    isFreeToolbar,
    modality,
    canShowBookshelf,
    sortedVersions,
    isLatestVersion,
    translations,
    isLesson,
  } = courseStore;

  const isBookshelfEnabled = useFeature({
    section: 'course',
    flagName: 'bookshelf_button_active',
    defaultFlagValue: false,
  });

  const isEbookDownloaderEnabled = useFeature({
    section: 'course',
    flagName: 'pdf_ebook_button_active',
    defaultFlagValue: false,
  });
  const canShowEbookDownloader = !isLesson && isEbookDownloaderEnabled;

  const prepareFeedback = () => {
    const data = {
      course: courseCode,
      courseVersion,
      pageTitle: page?.title,
      uuid: page?.uuid,
      url: window.location.href,
    };
    eventStore.toggleFeedbackForm(data);
  };

  if (!loadedInitialData) {
    return null;
  }

  const paidToolbar = !isFreeToolbar ? (
    <>
      {/* feedback */}
      <Button
        variant="link"
        icon={<BullHornIcon />}
        className="feedback-toggle"
        onClick={() => {
          prepareFeedback();
        }}
        data-analytics-id="feedback-btn-ole-lp"
      >
        {t('Feedback')}
      </Button>
      {/* ebook downloader */}
      {canShowEbookDownloader && (
        <EBookDownloader
          modality={modality}
          course={{
            code: courseCode,
            version: courseVersion,
          }}
          user={userStore.user}
          data-analytics-id="ebook-download-btn-ole-lp"
        />
      )}
      {/* bookshelf */}
      {isBookshelfEnabled && canShowBookshelf && (
        <Flex
          justifyContent={{
            default: 'justifyContentCenter',
          }}
          alignItems={{ default: 'alignItemsCenter' }}
          data-analytics-id="bookshelf-btn-ole-lp"
        >
          <Button
            style={{ flex: 1 }}
            component="a"
            href={`${configStore.ebooksPlatformURL}ebook/${courseSlug}`}
            variant="secondary"
            target="_blank"
            data-analytics-id={`bookshelf-btn-ole-lp-${courseSlug}`}
          >
            {t('Access Bookshelf')}
          </Button>
        </Flex>
      )}
    </>
  ) : null;

  return (
    <div className="hud-course-view__toolbar">
      <div className="hud-course-view__toolbar-left">
        {canShowCertAttendanceButton ? (
          <CertificateOfAttendanceButton
            courseSlug={courseSlug}
            timestamp={legacyAttendanceTimestamp}
          />
        ) : null}
      </div>
      <div className="hud-course-view__toolbar-right">
        {paidToolbar}
        {/* Version */}
        {sortedVersions.length > 1 && (
          <VersionsDropdown
            code={courseCode}
            currentVersion={courseVersion}
            versions={sortedVersions}
            isLatestVersion={isLatestVersion}
            usePatternfly
            onChangeRoute={({ routeName, routeParams }) => {
              routerStore.navigate(routeName, routeParams);
            }}
          />
        )}
        {/* language dropdown */}
        {translations?.length > 1 && (
          <LanguageDropdown
            callback={async (lang) => {
              await courseStore.saveEnrollmentUserData(lang);
            }}
            language={courseStore.currentCourse?.language}
            translations={translations}
            usePatternfly
          />
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(
  observer(CourseToolbar),
  ComponentError,
  handleError,
);
