import { action, observable, computed } from 'mobx';
import { PREMIUM_VT_SESSION_STATES } from '../../../config/constants';

import PremiumSessionsStore from '../../../stores/PremiumSessions';
import { getCourseTitleByLanguage } from '../../../services/UtilityService';

class PremiumSessionsCOAStore {
  @observable ready = false;

  @observable premSessionOfferingSlug = '';

  constructor(catalogStore, premiumSessionsStore, classesStore, i18n) {
    this.catalogStore = catalogStore;
    this.premiumSessionsStore = premiumSessionsStore;
    this.classesStore = classesStore;
    this.i18n = i18n;
  }

  @action extendPremiumSession = (sessions, enrolledSessions) => {
    // include enrollment for each  scheduled session
    return sessions?.map((session) => {
      const sessionslug = `${this.premSessionOfferingSlug}-${session.slug}`;

      let scheduledSessionWithEnrollment =
        this.premiumSessionsStore
          .getScheduledSessionsBySessionSlug(sessionslug)
          ?.map((scheduledSession) => {
            const enrollment = enrolledSessions?.find(
              (e) => e.premvt_session_uuid === scheduledSession.doc_id,
            );
            return {
              enrollment,
              enrollmentState: enrollment?.state || null,
              ...scheduledSession,
            };
          }) || [];

      // ignore session that have no enrollment or have enrollment in past and have not attended.
      scheduledSessionWithEnrollment = scheduledSessionWithEnrollment
        ?.filter(
          (se) =>
            se.enrollment != null ||
            (se.enrollment?.state === 'enrolled' &&
              PremiumSessionsStore.getMinutesBeforeSessionStarts(
                se.start_time,
              ) > 0),
        )
        ?.slice()
        ?.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));

      // group enrollments by state
      return {
        ...session,
        enrollment: PremiumSessionsCOAStore.groupByEnrollmentState(
          scheduledSessionWithEnrollment,
        ),
      };
    });
  };

  static groupByEnrollmentState(arr) {
    const key = 'enrollmentState';
    // Create a sub-array with data broken down by each enrollmentState
    const sets = arr.reduce((groups, item) => {
      const val = item[key];
      const set = groups;

      set[val] = set[val] || [];

      set[val].push(item);
      return set;
    }, {});
    return sets;
  }

  @computed get totalAttendedEnrollments() {
    return (
      this.classesStore.groupedPremiumSessionEnrollmentsByOffering[
        this.premSessionOfferingSlug
      ]?.filter((e) => e.state === PREMIUM_VT_SESSION_STATES.completed)
        ?.length || 0
    );
  }

  @computed get totalPremiumSessions() {
    return (
      this.premiumSessionsStore.groupedPremiumSessionContentByOffering[
        this.premSessionOfferingSlug
      ]?.sessions?.length || 0
    );
  }

  @computed get hasAttendedAllSessions() {
    const hasSessionWithNoEnrollment = this.premiumSessionsByCourseSlug?.find(
      (p) =>
        p.enrollment &&
        (Object.keys(p.enrollment)?.length <= 0 ||
          !Object.keys(p.enrollment).includes(
            PREMIUM_VT_SESSION_STATES.completed,
          )),
    );

    return hasSessionWithNoEnrollment === undefined;
  }

  @computed get premiumSessionsByCourseSlug() {
    const filteredSessions =
      this.premSessionOfferingSlug !== ''
        ? this.premiumSessionsStore.groupedPremiumSessionContentByOffering[
            this.premSessionOfferingSlug
          ]
        : [];

    const premSessionWithEnrollments = this.extendPremiumSession(
      filteredSessions?.sessions,
      this.classesStore.groupedPremiumSessionEnrollmentsByOffering[
        this.premSessionOfferingSlug
      ],
    );

    return premSessionWithEnrollments;
  }

  @action courseTitle = (offeringSlug) => {
    const courseCode = offeringSlug.split('-')[0];
    const titleToReturn =
      getCourseTitleByLanguage(
        this.catalogStore.uniqueCatalogEntries[courseCode]?.translations,
        this.i18n.language,
      ) || '';

    return titleToReturn;
  };

  @computed get allPremiumOfferings() {
    return this.premiumSessionsStore.premiumSessionsContent
      ?.slice()
      .sort((a, b) => a.offering_slug.localeCompare(b.offering_slug))
      ?.map((entry) => {
        return {
          label: `${entry.offering_slug.toUpperCase()} - ${this.courseTitle(
            entry.offering_slug,
          )}`,
          value: entry.offering_slug,
        };
      });
  }

  @computed get scheduledCompletedUserPremiumOfferings() {
    const scheduledCompletedOfferingSlug =
      this.classesStore.getPremiumSessionEnrollmentsOfferingSlugForCurrentUser;

    return this.premiumSessionsStore.premiumSessionsContent
      ?.slice()
      .sort((a, b) => a.offering_slug.localeCompare(b.offering_slug))
      ?.filter((entry) => {
        return scheduledCompletedOfferingSlug?.includes(entry.offering_slug);
      })
      ?.map((entry) => {
        return {
          label: `${entry.offering_slug.toUpperCase()} - ${this.courseTitle(
            entry.offering_slug,
          )}`,
          value: entry.offering_slug,
        };
      });
  }

  @computed get selectedPremOfferingSlugWithTitle() {
    return {
      label: `${this.premSessionOfferingSlug.toUpperCase()} - ${this.courseTitle(
        this.premSessionOfferingSlug,
      )}`,
      value: this.premSessionOfferingSlug,
    };
  }
}

export default PremiumSessionsCOAStore;
