import { observable, action, computed } from 'mobx';

class ManageUsersInPathStore {
  @observable showAddUserModal = false;

  @observable showConfirmModal = false;

  @observable selectedUsers = new Set();

  @observable selectedOrgs = new Set();

  @observable isAllOrgSelected = false;

  @observable userListFetched = false;

  @observable isAllUsersSelected = false;

  @observable _currentPage = 1;

  @observable entriesPerPage = 10;

  @observable sortUserByField = 'username';

  @observable isSortUserAscending = true;

  @observable _filteredUsers = [];

  @observable userListToAdd = [];

  @observable userSearchQuery = '';

  constructor(organizationStore, userStore) {
    this.organizationStore = organizationStore;
    this.userStore = userStore;
  }

  @computed get selectedUserNames() {
    return [...this.selectedUsers];
  }

  @computed get selectedOrganizationIds() {
    return [...this.selectedOrgs];
  }

  @computed get filteredUsers() {
    return this.userSearchQuery || this.selectedOrganizationIds?.length > 0
      ? this._filteredUsers
      : [];
  }

  @computed get paginatedUserEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.filteredUsers.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return Math.ceil(this.filteredUsers.length / this.entriesPerPage, 10) || 1;
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @action searchUsers = (searchFor) => {
    let _searchResults =
      this.selectedOrganizationIds.length > 0
        ? this.userListToAdd.filter((user) =>
            this.selectedOrganizationIds.includes(user.org_id),
          )
        : this.userListToAdd;

    if (!searchFor.length) {
      return _searchResults;
    }

    _searchResults = _searchResults.filter((user) => {
      return (
        user.username.toLowerCase().includes(searchFor.toLowerCase()) ||
        user.email.toLowerCase().includes(searchFor.toLowerCase()) ||
        user.organizationName.toLowerCase().includes(searchFor.toLowerCase()) ||
        user.managerName.toLowerCase().includes(searchFor.toLowerCase())
      );
    });

    return _searchResults;
  };

  @action sortUsers = (users) => {
    if (!this.isSortUserAscending) {
      return users
        .slice()
        .sort((a, b) =>
          b[this.sortUserByField].localeCompare(a[this.sortUserByField]),
        );
    }

    return users
      .slice()
      .sort((a, b) =>
        a[this.sortUserByField].localeCompare(b[this.sortUserByField]),
      );
  };

  @action filterUsers = () => {
    const _filteredUsers = this.searchUsers(this.userSearchQuery);
    this._filteredUsers = this.sortUsers(_filteredUsers);
    return this._filteredUsers;
  };

  @action onSearchUsers = (searchFor) => {
    this.userSearchQuery = searchFor.trim();
    this.filterUsers();
  };

  filterUsersListBySubscription = (users, subscription) => {
    return (
      users
        ?.filter((user) => user.subscription === subscription)
        ?.map((user) => {
          return {
            ...user,
            ...(user.org_id && {
              organizationName: this.organizationStore.getOrganizationName(
                user.org_id,
              ),
            }),
            ...(user.manager
              ? {
                  managerName:
                    user.manager.preferred_name || user.first_name
                      ? // eslint-disable-next-line camelcase
                        `${user.manager?.first_name} ${
                          // eslint-disable-next-line camelcase
                          user.manager?.last_name
                        }`
                      : user.manager?.username,
                }
              : { managerName: '' }),
          };
        }) || []
    );
  };

  @action getUsersListBySubscription = async (subscription, userListToAdd) => {
    //
    try {
      this.userListToAdd = this.filterUsersListBySubscription(
        userListToAdd,
        subscription,
      );
    } catch {
      this.userListToAdd = [];
    } finally {
      return this.userListToAdd;
    }
  };

  @action addUsersToPath(usersToAdd) {
    usersToAdd.forEach((username) => {
      this.selectedUsers.add(username);
    });

    return this.selectedUsers;
  }

  @action removeUsersToPath(userSubscriptionToRemove) {
    userSubscriptionToRemove.forEach((username) => {
      this.selectedUsers.delete(username);
    });

    return this.selectedUsers;
  }

  @action toggleConfirmModal() {
    this.showConfirmModal = !this.showConfirmModal;
  }

  @action onUserSelection = (e, username) => {
    const { checked } = e.target;
    let usersToManage = [username];
    const filteredUsers = this.userListToAdd;
    if (username === 'all-user') {
      usersToManage = filteredUsers?.map((u) => u.username);
    }
    const selectedUsers = checked
      ? this.addUsersToPath(usersToManage)
      : this.removeUsersToPath(usersToManage);
    this.isAllUsersSelected = selectedUsers.size >= filteredUsers.length;
    return this.selectedUsers;
  };

  @computed get allOrganizationsByRole() {
    return this.organizationStore.filteredOrganizations(
      this.userStore.isOrganizationAdmin ? 'admin' : 'manager',
    );
  }

  @action onOrgSelection = (e, orgId) => {
    const { checked } = e.target;
    let orgsToManage = [orgId];
    const filteredOrgs = this.allOrganizationsByRole;
    if (orgId === 'all-org') {
      orgsToManage = filteredOrgs?.map((o) => o.doc_id);
    }
    const selectedOrgs = checked
      ? this.addOrgs(orgsToManage)
      : this.removeOrgs(orgsToManage);
    this.isAllOrgSelected = selectedOrgs.size >= filteredOrgs.length;
    this.filterUsers();
    return this.selectedOrgs;
  };

  @action addOrgs = (orgsToAdd) => {
    orgsToAdd.forEach((orgId) => {
      this.selectedOrgs.add(orgId);
    });
    return this.selectedOrgs;
  };

  @action removeOrgs = (orgsToRemove) => {
    orgsToRemove.forEach((orgId) => {
      this.selectedOrgs.delete(orgId);
    });
    return this.selectedOrgs;
  };
}

export default ManageUsersInPathStore;
