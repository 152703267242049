import React from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import TableOfContents from 'components/Course/TableOfContents';

type Props = {
  courseStore: any;
};

const ToCTab = ({ courseStore }: Props) =>
  courseStore.page &&
  courseStore.courseStructure && (
    <TableOfContents structure={courseStore.courseStructure} />
  );

export default withErrorBoundary(observer(ToCTab), ComponentError, handleError);
