import React, { useState } from 'react';
import { HUD, SettingsPanel, ToC } from '@ole-ui/ole-ui-components';
import { Link } from 'react-mobx-router5';
import SettingsIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon';
// import { useTranslation } from 'react-i18next';
import useMobxStores from 'hooks/useMobxStores';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import CourseStore from './store';

type Props = {
  courseStore: CourseStore;
  onDirectionChange: (direction: 'next' | 'prev') => void;
};

const CourseHUD = (props: Props) => {
  const { courseStore, onDirectionChange } = props;
  //   const { t } = useTranslation();
  const { routerStore } = useMobxStores();
  const { route } = routerStore;
  const [activeHUDPanel, setActiveHUDPanel] = useState<string | null>(null);

  const {
    next_tag: nextPageTag,
    page_tag: currentPageTag,
    prev_tag: previousPageTag,
  } = courseStore.page ?? {};

  const sections =
    courseStore.courseStructure?.filter((item) => item.type === 'section') ||
    [];

  const currentPageNumber =
    (sections.findIndex((item) => item.page_tag === currentPageTag) || 0) + 1;

  const totalPageNumber = sections.length;

  return (
    <HUD
      key="course-hud"
      className="hud-course-view__hud pf-v5-theme-dark"
      localStorageKeyPrefix="course-hud"
      onDirectionChange={onDirectionChange}
      position="sticky"
      itemMaxHeightOffset={0}
      progress={courseStore.courseTotalProgress}
      progressBarProps={{
        'aria-label': 'Progress bar',
      }}
      activeItem={activeHUDPanel}
      onActiveItemChange={(activeItem: string | null) => {
        setActiveHUDPanel(activeItem);
      }}
    >
      <HUD.MainItem
        hasPadding
        isResizable
        minHeight={200}
        label={courseStore.page?.title || ''}
        buttonProps={{
          'aria-label': 'Toggle Table of Contents panel',
        }}
      >
        <ToC
          courseTitle={courseStore.title}
          structure={courseStore.courseStructure}
          progressStructure={courseStore.progressStructure}
          renderLink={(structureItem) => (
            <Link
              href
              routeName="courses:page"
              routeParams={{
                course: (route as any).params.course, // eg. 'le101-1.0' or 'rh124-9.0'
                page: structureItem.page_tag, // eg. 'ch02s03' (legacy) or 'accessing-the-command-line-using-the-desktop' (unique universal id for pages)
              }}
              onClick={() => {
                if (structureItem.page_tag !== currentPageTag) {
                  setActiveHUDPanel(null);
                }
              }}
              data-analytics-id="toc-link-ole-lp"
            >
              {structureItem.title}
            </Link>
          )}
          currentPageUrl={(route as any).params.page}
        />
      </HUD.MainItem>
      <HUD.Navigation
        currentPage={currentPageNumber}
        totalPages={totalPageNumber}
        onNavigate={(hudDirection) => {
          if (hudDirection === 'next') {
            routerStore.navigate('courses:page', {
              course: (route as any).params.course,
              page: nextPageTag || (route as any).params.course,
            });
          } else if (hudDirection === 'prev') {
            routerStore.navigate('courses:page', {
              course: (route as any).params.course,
              page: previousPageTag || (route as any).params.course,
            });
          }
        }}
        prevButtonProps={{
          'aria-label': 'Go to previous page',
        }}
        nextButtonProps={{
          'aria-label': 'Go to next page',
        }}
      />
      <HUD.Items key="items">
        <HUD.Item
          eventKey="1"
          title="Settings"
          icon={<SettingsIcon />}
          hasPadding
          buttonProps={{
            'aria-label': 'Toggle settings panel',
          }}
        >
          {(contextValues) => (
            <div style={{ width: '100%', height: '100%' }}>
              <SettingsPanel
                onDirectionChange={(hudDirection) => {
                  contextValues.setDirection(hudDirection);
                }}
                currentDirection={contextValues.direction}
              />
            </div>
          )}
        </HUD.Item>
      </HUD.Items>
    </HUD>
  );
};
export default withErrorBoundary(
  observer(CourseHUD),
  ComponentError,
  handleError,
);
