/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo } from 'react';
import { Button } from '@patternfly/react-core';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import 'react-photo-view/dist/react-photo-view.css';
import './styles.scss';

/**
 * Recursively replace img tags by the image preview component. Nested children limit is 6 to prevent performance hits.
 * @param {*} node html node
 * @param {*} iteration counter to control when to stop
 */
function replaceImage(node, iteration = 1) {
  if (node.name === 'img') {
    const imageClassName = node.attribs?.class || '';
    return (
      <PhotoProvider
        photoClassName="image-previewer__image"
        bannerVisible={false}
        maskOpacity={0.9}
        overlayRender={(overlayProps) => (
          <div className="image-previewer__overlay">
            <Button
              className="image-previewer__button"
              onClick={overlayProps.onClose}
              variant="plain"
              aria-label="Action"
            >
              <TimesIcon size="lg" />
            </Button>
          </div>
        )}
      >
        <PhotoView src={node.attribs?.src}>
          <img
            {...node.attribs}
            className={`image-previewer__preview ${imageClassName}`}
          />
        </PhotoView>
      </PhotoProvider>
    );
  }

  if (iteration > 6) return node;

  if (node.children) {
    return {
      ...node,
      children: node.children.map((child) => {
        return replaceImage(child, iteration + 1);
      }),
    };
  }

  return node;
}

/**
 * Replace all <img> tags present in the content by an image previewer component that allows zooming and dragging for all device sizes.
 */
function ImagePreviewer({ data }) {
  const htmlParser = new DOMParser();
  const node = htmlParser.parseFromString(data, 'text/html');
  const html = node.body.innerHTML;

  const parserOption = {
    replace: (n) => {
      return replaceImage(n);
    },
  };

  const parsed = useMemo(() => {
    return Parser(html, parserOption);
  }, [data]);

  return <>{parsed}</>;
}

ImagePreviewer.propTypes = {
  data: PropTypes.string.isRequired,
};

export default withErrorBoundary(
  React.memo(ImagePreviewer),
  ComponentError,
  handleError,
);
