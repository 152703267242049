/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { SENTRY_PUBLIC_KEY } from './constants';

const isProduction = process.env.NODE_ENV === 'production';

const configureSentry = (shouldSkipSentry) => {
  if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
    console.info('Skipping sentry initialization on localhost...');
    return;
  }

  if (shouldSkipSentry) {
    console.info('Skipping sentry initialization on China instance...');
    return;
  }

  const denyUrls = [
    /extensions\//i,
    /^chrome:\/\//i,
    'intercom.io',
    'intercomcdn.com',
    'trustarc.com',
    'pendo.io',
    'data.pendo.io',
    'kaltura.com',
    'cfvod.kaltura.com',
    'analytics.kaltura.com',
    'cdnapisec.kaltura.com',
    'consent.trustarc.com',
    'consent-pref.trustarc.com',
    'widget.intercom.io',
  ];

  const shouldIgnoreSpan = (span) => {
    return denyUrls.some((url) => {
      if (url instanceof RegExp) {
        return (
          url.test(span?.data?.['server.address']) || url.test(span?.data?.url)
        );
      }

      const matchServerAddress = span?.data?.['server.address']?.includes(url);
      const matchURL = span?.data?.url?.includes(url);

      return matchServerAddress || matchURL;
    });
  };

  Sentry.init({
    release: isProduction ? process.env.RELEASE_VERSION_NUMBER : undefined, // only in production
    environment: process.env.APP_ENVIRONMENT,
    dsn: SENTRY_PUBLIC_KEY,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isProduction ? 0.25 : 1.0,
    debug: false,
    // allowUrls or denyUrls to prevent monotoring other services like pendo or similar
    denyUrls,
    ignoreErrors: [
      'Request aborted',
      'AxiosError: Network Error',
      'airplay', // kaltura
      '404 (Not Found)',
      'kaltura player',
      '[HlsAdapter]',
      '[Error] Category:7',
      'pendo.io',
      /.*(http:|https:).* 4\d\d$/, // ignore errors that contain http(s) and end with 4xx
      /^(.*?)AxiosError: Request failed with status code 4\d\d$/,
      /AxiosError: Request failed with status code 4\d\d$/,
      /\[Error\] Category:(\d+) \| Code:(\d+) \| \[object Object\]/g,
      /\[Error\] Category:(\d+) \| Code:(\d+) \| (.*)/g,
    ],
    sanitizeKeys: ['csrftoken', 'x-CSRFToken', 'password'],
    beforeSendTransaction: (transaction) => {
      if (transaction?.spans) {
        transaction.spans = transaction.spans.filter((span) => {
          return !shouldIgnoreSpan(span);
        });
      }

      return transaction;
    },
  });
};

export const reportError = (
  error,
  errorInfo,
  tag = 'error_boundary',
  level = 'error',
) => {
  try {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      scope.setTag('event_source', tag);

      if (level) {
        scope.setLevel(level);
      }

      const eventId = Sentry.captureException(error);

      if (process.env.NODE_ENV === 'development') {
        console.log('Sentry eventId for error boundary sent:', eventId);
      }
    });
  } catch (e) {
    console.warn('Could not report to sentry.', e);
  }
};

export const reportLoggedUser = (user) => {
  Sentry.setUser({
    email: user.email,
    id: user.id,
    username: user.username,
  });
  Sentry.setTag('sessionId', user.sessionId);
};

export const reportAppLanguage = (language) => {
  Sentry.setTag('app_language', language);
};

export default configureSentry;
