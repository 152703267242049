import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { AngleRightIcon, AngleLeftIcon } from '@patternfly/react-icons';
import { Link } from 'react-mobx-router5';
import { storesContext } from 'stores';

const Navigation = (props) => {
  const { t } = useTranslation();
  const { routerStore } = useContext(storesContext);

  const { route } = routerStore;

  const { next, previous, scroll, analyticsIdPrefix } = props;

  const NavButton = (direction, link, isDisabled) => (
    <Link
      href
      routeName="courses:page"
      data-analytics-id={`${analyticsIdPrefix}-${direction}-btn-ole-lp`}
      routeParams={{
        course: route.params.course,
        page: link,
      }}
      {...(scroll && {
        onClick: () =>
          window.scrollTo({
            top: 0,
          }),
      })}
      className={`pf-v5-c-button ${
        isDisabled ? ' pf-m-disabled' : ' pf-m-primary'
      }`}
    >
      {direction === 'next' ? (
        <span className="right-page-navigation">
          {t('Next')} <AngleRightIcon />
        </span>
      ) : (
        <span className="left-page-navigation">
          <AngleLeftIcon />
          {t('Previous')}
        </span>
      )}
    </Link>
  );

  return (
    <div className="navigation">
      {previous
        ? NavButton('previous', previous)
        : NavButton('previous', route.params.course, true)}
      {next
        ? NavButton('next', next)
        : NavButton('next', route.params.course, true)}
    </div>
  );
};

Navigation.propTypes = {
  next: PropTypes.string,
  previous: PropTypes.string,
  scroll: PropTypes.bool,
  analyticsIdPrefix: PropTypes.string,
};

Navigation.defaultProps = {
  next: null,
  previous: null,
  scroll: false,
  analyticsIdPrefix: '',
};

export default observer(Navigation);
