import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TechnologyChart, ChartFrame } from '@ole-ui/ole-ui-components';
import { Text, TextVariants } from '@patternfly/react-core';
import { getTopItems, pieChartcolorScale } from '../helpers';

function TechnologyChartsFrame({ products, categories }) {
  const { t } = useTranslation();

  const topProducts = React.useMemo(() => getTopItems(products), [products]);
  const topCategories = React.useMemo(
    () => getTopItems(categories),
    [categories],
  );

  let productChart = null;
  let isLoadingProducts = true;

  let categoryChart = null;
  let isLoadingCategories = true;

  if (topProducts) {
    isLoadingProducts = false;
    const data = topProducts.map((product) => ({
      x: product.label,
      y: product.value,
    }));

    const legendData = topProducts.map((product) => ({
      name: product.label || '',
    }));

    productChart = (
      <div className="chart-fade-in">
        <Text className="chart-print-title" component={TextVariants.h5}>
          {t('Product')}
        </Text>
        <TechnologyChart
          ariaDesc={t(
            'A pie chart showing the distribution of Red Hat products learned in your organization',
          )}
          data={data}
          colorScale={pieChartcolorScale}
          legendData={legendData}
        />
      </div>
    );
  }

  if (topCategories) {
    isLoadingCategories = false;
    const data = topCategories.map((category) => ({
      x: category.label,
      y: category.value,
    }));

    const legendData = topCategories.map((category) => ({
      name: category.label || '',
    }));

    categoryChart = (
      <div className="chart-fade-in">
        <Text className="chart-print-title" component={TextVariants.h5}>
          {t('Category')}
        </Text>
        <TechnologyChart
          ariaDesc={t(
            'A pie chart showing the distribution of Red Hat technology categories learned in your organization',
          )}
          data={data}
          colorScale={pieChartcolorScale}
          legendData={legendData}
        />
      </div>
    );
  }

  const popoverContent = (
    <>
      <p>
        <b>{t('Products:')}</b> {t('Red Hat Products')}
      </p>
      <p>
        <b>{t('Categories:')}</b> {t('IT Technology Verticals')}
      </p>
    </>
  );

  return (
    <ChartFrame
      subTitle={t('Courses by technology')}
      popoverHeader={<div>{t('Courses by technology')}</div>}
      popoverContent={popoverContent}
      tabs={[
        {
          key: 'product',
          title: 'Product',
          chart: productChart,
          isLoading: isLoadingProducts,
        },
        {
          key: 'category',
          title: 'Category',
          chart: categoryChart,
          isLoading: isLoadingCategories,
        },
      ]}
    />
  );
}

const statsShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
});

TechnologyChartsFrame.propTypes = {
  products: PropTypes.objectOf(statsShape),
  categories: PropTypes.objectOf(statsShape),
};

TechnologyChartsFrame.defaultProps = {
  products: null,
  categories: null,
};

export default TechnologyChartsFrame;
