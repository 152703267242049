import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Select, SelectOption } from '@patternfly/react-core/deprecated';
import { Flex, FlexItem } from '@patternfly/react-core';

import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';

import './styles.scss';
import { ManagementDashboardTab } from '../types';

const OrgFormControl = (props) => {
  const { t, i18n } = useTranslation();
  const [isOrgSelectorOpen, setIsOrgSelectorOpen] = useState(false);
  const [isSubscriptionSelectorOpen, setIsSubscriptionSelectorOpen] =
    useState(false);
  const { organizationStore, userStore, vocabularyStore } =
    useContext(storesContext);

  const filteredOrgs = organizationStore.organizations.filter((org) => {
    const admins = org.admin_set.map((user) => {
      return user.username;
    });
    const managers = org.manager_set.map((user) => {
      return user.username;
    });
    return [...admins, ...managers].includes(userStore.user?.username);
  });

  if (
    organizationStore.isLoadingOrganizations ||
    organizationStore.didFailToFetchOrganizations
  ) {
    return null;
  }

  return (
    <Flex
      alignItems={{ default: 'alignItemsCenter' }}
      justifyContent={{ default: 'justifyContentCenter' }}
      className="organization-form"
    >
      <FlexItem>
        <Select
          data-analytics-id="org-dropdown-mngmt-dashboard-lp"
          className="organization-form__select"
          isOpen={isOrgSelectorOpen}
          isDisabled={filteredOrgs?.length < 1}
          onToggle={() => setIsOrgSelectorOpen((prev) => !prev)}
          selected={organizationStore.organization?.id}
          selections={organizationStore.organization.name}
          onSelect={(event, selection) => {
            organizationStore.setOrganization(selection);
            // TODO 2: loading state when switching orgs
            setIsOrgSelectorOpen(false);

            if (
              organizationStore.isAdminOfOrg(
                organizationStore.findOrganizationById(selection),
              )
            ) {
              props.setActiveTab(ManagementDashboardTab.Summary);
            } else {
              props.setActiveTab(ManagementDashboardTab.Team);
            }
          }}
        >
          {filteredOrgs.map((org) => {
            return (
              <SelectOption key={org.doc_id} value={org.doc_id}>
                {org.name}
              </SelectOption>
            );
          })}
        </Select>
      </FlexItem>

      {props.showSubscriptionTypeFilter && (
        <FlexItem>
          <Select
            data-analytics-id="subscription-dropdown-mngmt-dashboard-lp"
            className="organization-form__select"
            isOpen={isSubscriptionSelectorOpen}
            isDisabled={filteredOrgs?.length < 1}
            onToggle={() => setIsSubscriptionSelectorOpen((prev) => !prev)}
            selections={organizationStore.summaryType}
            onSelect={(event, selection) => {
              organizationStore.switchUsageSummary(
                i18n,
                vocabularyStore,
                selection,
              );
              setIsSubscriptionSelectorOpen(false);
            }}
          >
            <SelectOption
              key="all"
              value="all"
              data-analytics-id="all-option-subscription-dropdown-mngmt-dashboard-lp"
            >
              {t('All subscriptions')}
            </SelectOption>
            <SelectOption
              key="current"
              value="current"
              data-analytics-id="current-option-subscription-dropdown-mngmt-dashboard-lp"
            >
              {t('Current subscriptions')}
            </SelectOption>
          </Select>
        </FlexItem>
      )}
    </Flex>
  );
};

OrgFormControl.propTypes = {
  showSubscriptionTypeFilter: PropTypes.bool,
  setActiveTab: PropTypes.func.isRequired,
};

OrgFormControl.defaultProps = {
  showSubscriptionTypeFilter: false,
};

export default withErrorBoundary(
  observer(OrgFormControl),
  GenericViewError,
  handleError,
);
