import React, { Suspense } from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import BookmarkButton from 'components/Course/BookmarkButton';
import ProgressMap from 'components/Course/ProgressMap';
import Navigation from 'components/Course/Navigation';
import useMobxStores from 'hooks/useMobxStores';
import SubscriptionComparisonTable from 'components/Subscriptions/ComparisonTable';
import VideoWidget from 'components/Video/Widget';
import ContentArea from 'components/Course/ContentArea';
import { KALTURA_CONFIGS } from 'config/constants';
import PageSkeletonLoading from '../../skeletons/PageSkeletonLoading';
import CourseStore from '../store';

const SyndicationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'syndication-component' */ 'components/SyndicationComponent'
    ),
);

const ContentVersionHint = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'content-version-hint' */ 'components/ContentVersionHint'
    ),
);

type Props = {
  courseStore: CourseStore;
};

const CourseTab = ({ courseStore }: Props) => {
  const { uiStore } = useMobxStores();
  const { isChineseInstance } = uiStore;
  const {
    courseSlug,
    courseCode,
    euuid,
    pageSlug,
    page,
    content,
    isLoadingPage,
    courseStructure,
    progressStructure,
    pageTitle,
    isBlockedForFreeTier,
    buildEnv,
    isCurrentPageBookmarked,
    courseOffering,
    pageWithVideo,
    handleBookmark,
    videoWidgetTitle,
    isVideoAllowed,
    customVideoPlayerId,
  } = courseStore;

  const {
    next_tag: nextPageTag,
    page_tag: currentPageTag,
    prev_tag: previousPageTag,
  } = page ?? {};

  return (
    <div id="tab-course-content" className="hud-course-view__tab-content">
      <div className="hud-course-view__bookmark">
        <BookmarkButton
          sectionTitle={pageTitle}
          sectionSlug={pageSlug}
          isBooked={isCurrentPageBookmarked}
          courseOffering={courseOffering}
          handleBookmark={handleBookmark}
        />
      </div>
      <div className="hud-course-view__progress-map hud-course-view__fade-in">
        <ProgressMap
          structure={courseStructure}
          progressStructure={progressStructure}
          pageSlug={pageSlug}
        />
      </div>
      {isBlockedForFreeTier && (
        <div className="free-tier-message-wrapper">
          <p>
            This content is unavailable during your free trial. Click a section
            from Chapter 1 or Chapter 2 in the progress bar above, to return to
            your free trial course content.
          </p>
          <p>
            Please subscribe to the Red Hat Learning Subscription to receive
            access to all of the course content.
          </p>
          <SubscriptionComparisonTable />
        </div>
      )}
      <Navigation
        next={nextPageTag}
        previous={previousPageTag}
        analyticsIdPrefix="top"
      />
      {isVideoAllowed && (
        <VideoWidget
          entryId={pageWithVideo.entry_id}
          partnerId={KALTURA_CONFIGS.partnerId}
          playerId={customVideoPlayerId}
          isPlaylist={Boolean(pageWithVideo?.is_playlist)}
          videoWidgetTitle={videoWidgetTitle}
        />
      )}
      {isLoadingPage ? (
        <div className="course__skeleton">
          <PageSkeletonLoading />
        </div>
      ) : (
        <div className="course__content-wrapper ">
          <ContentArea
            key={currentPageTag}
            euuid={euuid}
            page={page}
            content={content}
            language={courseStore.currentCourse?.language}
            pageInProgress={isLoadingPage}
          />
        </div>
      )}
      <Navigation
        next={nextPageTag}
        previous={previousPageTag}
        scroll
        analyticsIdPrefix="bottom"
      />
      {!isChineseInstance && (
        <div className="syndication-style">
          <Suspense fallback="Loading">
            <SyndicationComponent
              courseCode={courseCode.toUpperCase()}
              courseTitle={courseStore.title}
            />
          </Suspense>
        </div>
      )}
      <Suspense fallback="Loading">
        <ContentVersionHint
          courseSlug={courseSlug}
          language={courseStore.currentCourse?.language}
          buildEnv={buildEnv}
        />
      </Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(CourseTab),
  ComponentError,
  handleError,
);
