import React from 'react';

import { Skeleton } from '@patternfly/react-core';

export default function ToolbarSkeletonLoading() {
  return (
    <div className="hud-course-view__toolbar">
      <div className="hud-course-view__toolbar-left">
        <Skeleton width="230px" height="40px" />
      </div>
      <div className="hud-course-view__toolbar-right">
        <Skeleton width="130px" height="40px" />
        <Skeleton width="160px" height="40px" />
        <Skeleton width="130px" height="40px" />
        <Skeleton width="140px" height="40px" />
      </div>
    </div>
  );
}
