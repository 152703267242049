import aIBrain from '../img/RHLSbanner_AI.png';
import aITablet from '../img/RHLSbanner_AITablet.png';
import astronautFlag from '../img/RHLSbanner_AstronautFlag_grey.png';
import astronautLabPlus from '../img/LabPlusHeroBanner.png';
import astronautPaperboy from '../img/RHLSbanner_AstronautPaperboy.png';
import astronautsSocial from '../img/RHLSbanner_AstronautsSocial_Light.png';
import digitalBadge from '../img/RHLSbanner_DigitalBadge.png';
import heroBanner from '../img/hero-banner.png';
import bannerExact from '../img/banner-exact.png';
import bannerRemoteExams from '../img/RHLSbanner_RemoteExams.png';
import newsFlash from '../img/flash_news_astronaut.jpg';
import newsFlashTV from '../img/RHLSbanner_NewsFlashAstronaut.png';
import womanClouds from '../img/RHLSbanner_WomanClouds.png';
import retakesNewsFlash from '../img/RHLSbanner_ExamRetakesNewsflash.png';
import personAtComputer from '../img/RHLSbanner_PersonAtComputer.png';
import uxTrooper from '../img/ux-trooper-banner-v2.png';
import personHybridCloud from '../img/RHLSbanner_hybridCloud.png';
import computerAutomation from '../img/RHLSbanner_Automation.png';
import chatBeta from '../img/RHLS_Hero_ChatBeta.png';
import dashboardFeedback from '../img/feedback-dark.jpg';
import webinar from '../img/webinar-hero.png';
import newsFlash8 from '../img/newsflash03.png';
import RHLC200k from '../img/RHLSbanner_RHLC200k.png';
import SSOGraphic from '../img/RHLSbanner_SSO.png';
import techOverviews from '../img/RHLSbanner_TechOverviews.png';
import trainingBookshelf from '../img/RHLSbanner_Bookshelf.png';
import virtualization from '../img/RHLSbanner_VIRT.png';
import whiteboardBanner from '../img/RHLSbanner_whiteboarding.png';
import womanLaptop from '../img/RHLSbanner_WomanLaptop.png';

// DO NOT import animation json files unless they are really being used.
// By importing them the app bundle size is increased significantly and the loading times will also increase
// import earlyAccess from '../img/ea-animation.json';
// import skillsPath from '../img/skills-paths-edited.json';
// import eigthtTopTips from '../img/eightExamTips.json';
// import premium from '../img/premium_hero_animation.json';

import { APP_BASE } from '../config/constants';

const imgs = {
  aIBrain,
  aITablet,
  astronautFlag,
  astronautLabPlus,
  astronautPaperboy,
  astronautsSocial,
  heroBanner,
  bannerExact,
  newsFlash,
  bannerRemoteExams,
  digitalBadge,
  personAtComputer,
  retakesNewsFlash,
  personHybridCloud,
  computerAutomation,
  dashboardFeedback,
  webinar,
  newsFlash8,
  newsFlashTV,
  uxTrooper,
  chatBeta,
  womanClouds,
  RHLC200k,
  SSOGraphic,
  techOverviews,
  trainingBookshelf,
  virtualization,
  whiteboardBanner,
  womanLaptop,
};

export default [
  {
    title: `Join the Red Hat Learning Community 200,000 member contest!`,
    titleColor: `black`,
    description: `Celebrate with us as our Red Hat Learning Community surpasses 200,000 members! Ask a question and join a discussion with fellow #opensource enthusiasts. To celebrate this milestone, participate in our contest to earn badges and win prizes.`,
    descriptionColor: `black`,
    imageUrl: imgs.RHLC200k,
    imageAlt: 'Red Hat Learning Community contest image',
    actionType: 'openLink',
    actionText: 'Learn more',
    actionUrl: `https://learn.redhat.com/t5/Red-Hat-Learning-Community-News/Join-the-200K-RHLC-Celebration-Contest/ba-p/43293`,
    openActionInNewTab: true,
  },
  {
    title: `Migrate virtualized workloads to OpenShift Virtualization`,
    titleColor: `white`,
    description: `As IT leaders make changes to modernize their virtualization infrastructure through containers and Kubernetes, it is imperative that IT professionals be equipped with the skills to succeed using technologies like OpenShift Virtualization.`,
    descriptionColor: `white`,
    imageUrl: imgs.virtualization,
    imageAlt: 'Virtualization brand graphic',
    actionType: 'openLink',
    actionText: 'View course',
    actionUrl: `${APP_BASE}/courses/do316-4.14/pages/pr01`,
    openActionInNewTab: false,
    displayForSubscriptions: [
      'ls520',
      'ls220',
      'ls120',
      'ls130c',
      'ls105',
      'ls000',
      'ls101',
      'ls810',
      'ls999',
    ],
  },
  {
    title: `Extra! Extra!`,
    titleColor: `black`,
    description: `Read all about it! Subscribe to the Red Hat Learning Subscription newsletter to receive all of the latest updates available to you. This includes course and exam releases, new features, and important Red Hat Training announcements.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautPaperboy,
    imageAlt: 'Astronauts meeting virtually',
    actionType: 'openLink',
    actionText: 'Subscribe',
    actionUrl: `https://www.redhat.com/en/engage/red-hat-learning-subscription-newsletter-opt-in-20240522`,
    openActionInNewTab: true,
  },
  {
    title: `Red Hat Training Bookshelf`,
    titleColor: `black`,
    description: `Check out Red Hat Training Bookshelf, our ebook portal that delivers flexibility and convenience to your learning.`,
    descriptionColor: `black`,
    imageUrl: imgs.trainingBookshelf,
    imageAlt: 'Red Hat Training Bookshelf is now available',
    actionType: 'openLink',
    actionText: 'Learn more',
    actionUrl: `https://www.redhat.com/en/blog/red-hat-training-bookshelf-upgrades-your-learning-experience`,
    openActionInNewTab: true,
  },
  {
    title: `Discuss your course with peers`,
    titleColor: `black`,
    description: `Red Hat Learning Community discussions are embedded in the Learning Subscription. Explore discussions that are now available in all courses!`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautsSocial,
    imageAlt: 'Astronauts meeting virtually',
    actionType: 'openLink',
    actionText: 'Explore',
    actionUrl: `${APP_BASE}/courses/do101-4.6/pages/pr01`,
    openActionInNewTab: false,
    displayForSubscriptions: [
      'ls520',
      'ls220',
      'ls120',
      'ls130c',
      'ls105',
      'ls000',
      'ls101',
      'ls810',
      'ls300',
      'ls999',
    ],
  },
  {
    title: `Welcome to the Red Hat Learning Subscription Standard`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautFlag,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_41isrruz',
    displayForSubscriptions: ['ls220'],
  },
  {
    title: `Welcome to the Red Hat Learning Subscription Basic`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautFlag,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_h07quaro',
    displayForSubscriptions: ['ls120', 'ls130c', 'ls810'],
  },
  {
    title: `Welcome to the Red Hat Learning Subscription Developer`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautFlag,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_h2hmxml5',
    displayForSubscriptions: ['ls300'],
  },
  {
    title: `Take a tour of RHLS Premium`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautFlag,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_7nb59zuy',
    displayForSubscriptions: ['ls520', 'ls105', 'ls000', 'ls101', 'ls999'],
  },
];
